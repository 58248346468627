//material
import { Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

//delivery
import { MsTextField } from "@delivery/components/TextField/TextField";
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";

//hook
import useDetach from "@/store/hook/detach";

export function Detach() {
  const { uuid, setUUID, handleScan, loadingShowData, alert, handleOnClose } =
    useDetach();

  return (
    <Grid sx={{ width: "100%" }} item container>
      <Grid sx={{ py: 1 }}>
        <Typography variant="h5">Detach aggregation</Typography>
      </Grid>
      <Grid item container sx={{ py: 2 }}>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <MsTextField
            autofocus
            helperText="put cursor here to scan"
            value={uuid}
            label="Qr Code"
            onChange={(e) => setUUID(e.target.value)}
            onKeyEnter={(ev) => {
              if (ev.key === "Enter") {
                handleScan();
                ev.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <Box display={"flex"} justifyContent="start" alignSelf={"center"}>
            <LoadingButton
              loading={loadingShowData}
              loadingPosition="center"
              variant="contained"
              disableElevation
              onClick={handleScan}
            >
              Detach
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnClose}
      />
    </Grid>
  );
}
