// import { format } from 'date-fns';
import Moment from "moment-timezone";
// dd/mm/yyyy format

// export const formatDate = (date: any) => format(date, 'dd-MM-yyyy');

export const formatDateTime = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("DD MMM YYYY HH:mm:ss");
};

export const formatDate = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("DD MMM YYYY");
};

export const formatDateY = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("YYYY");
};

export const formatDateM = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("MM");
};

export const formatDateD = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("DD");
};

export const formatTimeH = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("HH");
};

export const formatDateNow = (date: any) => {
  //   Moment.locale('en');
  let dateNow = Moment(new Date()).format("DD MMM YYYY");
  let dateParam = Moment(date).utc().format("DD MMM YYYY");
  if (dateNow === dateParam) {
    let currentDatetime = Moment();
    let diffInMinutes = currentDatetime.diff(date, "minutes");

    if (diffInMinutes === 0) {
      return "Just now";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInMinutes < 1440) {
      return `${Math.floor(diffInMinutes / 60)} hours ago`;
    } else {
      return Moment(date).utc().format("HH:mm");
    }
  } else {
    return Moment(date).utc().format("DD MMM YYYY");
  }
};

export const formatDateYMD = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("YYYY-MM-DD");
};

export const formatDateYM = (date: any) => {
  //   Moment.locale('en');
  return Moment(date).utc().format("YYYY MMM");
};

export const formatDateBetween = (date1: any, date2: any) => {
  //   Moment.locale('en');
  let date1Month = Moment(date1).format("MMM");
  let date2Month = Moment(date2).format("MMM");

  if (date1Month === date2Month) {
    return `${Moment(date1).format("DD")} - ${Moment(date2).format(
      "DD"
    )} ${date1Month} ${Moment(date1).format("YYYY")}`;
  } else {
    return `${Moment(date1).format("DD MMM")} - ${Moment(date2).format(
      "DD MMM"
    )} ${Moment(date1).format("YYYY")}`;
  }
};
