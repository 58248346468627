import axiosClient from "@infrastructure/axios/axios";
import { Aggregation } from "@domain/model/aggregation";
import { AggregationQr } from "@domain/model/aggregationQr";
import { Product } from "@domain/model/product";

export async function exportQrAggregationCSV(uuid: any) {
  return await axiosClient({
    method: "GET",
    url: `/aggregation/qr/export-association/csv/${uuid}`,
  });
}

export async function fetchQrAggregationAssociation(uuid: any) {
  let res = await axiosClient({
    method: "GET",
    url: `/aggregation/qr/fetch-association/${uuid}`,
  });

  let dataModel = {
    actualTotal: res.data.actual_total,
    aggregation: Aggregation.fromJson(res.data.aggregation),
    aggregationQr: AggregationQr.fromJsonList(res.data.qr_aggregated),
  };

  return dataModel;

  // Aggregation.fromJson(res.data.aggregation);
  // return AggregationQr.fromJsonList(res.data.qr_aggregated);
}

export async function fetchQrAssociation(uuid: any) {
  let res = await axiosClient({
    method: "GET",
    url: `/aggregation/qr/fetch-qr/${uuid}`,
  });

  let dataModel = {
    productID: res.data.aggregation.product_id,
    product: Product.fromJson(res.data.aggregation.product),
    actualTotal: res.data.actual_total,
    aggregation: Aggregation.fromJson(res.data.aggregation),
    aggregationQr: AggregationQr.fromJsonList(res.data.qr_aggregated),
  };

  return dataModel;
}
