import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#2C4359",
      dark: "#60819B",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#BE969B",
      dark: "#ba000d",
      contrastText: "#fff",
    },
    success: {
      main: "#287A18",
      dark: "#287A18",
      contrastText: "#fff",
    },
    error: {
      main: "#A01B3A",
      dark: "#A01B3A",
    },
  },
});

export default theme;
