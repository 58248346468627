//react
import React from "react";

//material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import Button from "@mui/material/Button";

//icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//model
import { Aggregation } from "@domain/model/aggregation";
import { AggregationHistory } from "@domain/model/aggregationHistory";
import { AggregationQr } from "@domain/model/aggregationQr";

//delivery
import { CardAggregation } from "@delivery/pages/Admin/QrAggregation/CardAggregation";
import { TableQrList } from "@/delivery/pages/Admin/AggregationList/TableQrList";
import { Product } from "@/domain/model/product";

interface DialogDetailAggregationProps {
  title?: string;
  show: boolean;
  itemsProduct?: Product;
  itemsAggregation?: Aggregation;
  itemsAggregationHistory?: AggregationHistory[];
  itemsAggregationQr?: AggregationQr[];
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function DialogDetailAggregation(props: DialogDetailAggregationProps) {
  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      open={props.show}
      onClose={props.onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Detail Aggregation</DialogTitle>
      <DialogContent dividers={true}>
        <CardAggregation
          itemsProduct={props.itemsProduct}
          items={props.itemsAggregation}
        />
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Qr UUID</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableQrList items={props.itemsAggregationQr} />
          </AccordionDetails>
        </Accordion>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClickClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
