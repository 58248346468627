//react
import { useState } from "react";

//utility
import getUUID from "@/infrastructure/utils/get_uuid";

//repository
import { getWithGrandAssociation } from "@repository/api/aggregation";

//interface
import { Alert } from "@domain/interfaces/alert";

const useAssociation = () => {
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });
  const [uuid, setUUID] = useState("");
  const [loadingShowData, setLoadingShowData] = useState(false);
  const [subAggregation, setSubAggregation] = useState<any>([]);
  const [aggregation, setAggregation] = useState([]);
  const [showData, setShowData] = useState(false);

  //handle check on scan
  const handleScan = () => {
    let newUUID = getUUID(uuid);
    let data = {
      uuid: newUUID,
    };
    setLoadingShowData(true);
    getWithGrandAssociation(data)
      .then((result) => {
        setSubAggregation(result.subAggregations);
        setAggregation(result.aggregation);
        setLoadingShowData(false);
        setShowData(true);
        setUUID("");
      })
      .catch((error) => {
        setShowData(false);
        setUUID("");
        setLoadingShowData(false);
        setAlert({
          show: true,
          message: error.response.data.Detail,
          severity: "error",
        });
        setLoadingShowData(false);
      });
  };

  // ---------

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  return {
    alert,
    handleOnClose,
    uuid,
    setUUID,
    handleScan,
    loadingShowData,
    showData,
    aggregation,
    subAggregation,
  };
};

export default useAssociation;
