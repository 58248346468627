import useSetting from "@/store/hook/setting";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

interface SettingsProps {
  show: boolean;
  onClose?: (event: {}) => void;
  onSave?: React.MouseEventHandler<HTMLButtonElement> | undefined;
}

export function Settings(props: SettingsProps) {
  // const [setCookie, removeCookie] = useCookies(["check_qr"]);
  const { handleCheckOnScan, cookies, handeDefaultCheckOnScan } = useSetting();

  useEffect(() => {
    handeDefaultCheckOnScan();
  }, []);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={props.show}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Grid container sx={{ px: 1, py: 2 }}>
        <DialogTitle id="scroll-dialog-title">Setting</DialogTitle>
        <Grid item md={12} xs={12} lg={12}>
          <DialogContent dividers={true}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onClick={handleCheckOnScan}
                    checked={cookies.check_qr === "true"}
                  />
                }
                label="Check QR On Scan"
              />
            </FormGroup>
          </DialogContent>
        </Grid>
        <DialogActions sx={{ pl: 3 }}>
          <Button variant="text" size="small" onClick={props.onClose}>
            Close
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
}
