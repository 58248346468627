//react
import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

//material
import { SelectChangeEvent } from "@mui/material";

//repository
import { fetchAggregatePaginate } from "@repository/api/aggregation";
import { aggregate } from "@repository/api/aggregation";
import { fetchProduct } from "@repository/api/product";

//model
import { Product } from "@domain/model/product";

//hook
import useSetting from "@/store/hook/setting";
import useAggregateQrPack from "@/store/hook/aggregateQrPack";

const useAggregate = () => {
  const {
    aggProduct,
    setAggProduct,
    setUuid,
    uuid,
    setSubmitedUUID,
    submitedUUID,
    uuidCheckLoading,
    alert,
    handleAddUuid,
    handleRemove,
    handleRemoveList,
    aggregationList,
    setAggregationList,
    setQrList,
    qrList,
    setAlert,
    handleSelectedProduct,
  } = useAggregateQrPack();

  //checkbox
  const [autoScan, setAutoScan] = useState(true);
  const [autoPrint, setAutoPrint] = useState(false);

  //main form
  const [productSelected, setProductSelected] = useState(null);
  const [label, setLabel] = useState<string>("");
  const [qty, setQty] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [validation, setValidation] = useState(true);
  const [loadingSubmit, setLoadingsubmit] = useState(false);

  const [dataPrint, setDataPrint] = useState<any>(null);

  const [numberLabel, setNumberLabel] = useState(1);

  //start aggregate
  const [startAggregate, setStartAggregate] = useState({
    dataAgg: false,
    dataQr: true,
  });

  const [scanFocus, setScanFocus] = useState(false);

  const { cookies } = useSetting();
  let isCheckOnScan = cookies.check_qr === "true";

  //product name on aggregate
  // const [aggProduct, setAggProduct] = useState({ product_id: 0, name: "" });

  //---- END HOOK-----
  const navigate = useNavigate();

  //start aggregate
  const handleStartAggregate = () => {
    setScanFocus(true);
    let data = {
      // label: label,
    };
    fetchAggregatePaginate(data)
      .then((res) => {
        for (let x = 0; x < res.aggregation.length; x++) {
          if (res.aggregation[x].label === label) {
            setAlert({
              message: "duplicate name",
              severity: "error",
              show: true,
            });
            return;
          }

          setValidation(true);
          setStartAggregate({
            ...startAggregate,
            dataAgg: true,
            dataQr: false,
          });
        }
        if (res.aggregation.length === 0) {
          setValidation(true);
          setStartAggregate({
            ...startAggregate,
            dataAgg: true,
            dataQr: false,
          });
        }
      })
      .catch((err) => {});
  };

  //stop aggregate
  const handleStopAggregate = () => {
    window.location.reload();
    // setValidation(false);
    // setStartAggregate({ ...startAggregate, dataAgg: false, dataQr: true });
  };

  //handle product selected
  const handleChangeProductSelected = (event: any, value: any) => {
    handleSelectedProduct(event, value);
    setProductSelected(value.ID);
  };

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  //handle duplicate lable submit
  const handleLabelSubmit = (labelName: string) => {
    if (labelName === label) {
      setNumberLabel(numberLabel + 1);
      let number = numberLabel > 1 ? `${numberLabel}` : 1;
      let newLabel = `${label}/${number}`;
      return newLabel;
    }
  };

  //submit list
  const handleSubmit = () => {
    let UUIDList = [];
    for (let x = 0; qrList.length > x; x++) {
      UUIDList.push(qrList[x].UUID);
    }

    let dataSubmit = {
      product_id: productSelected?productSelected: (aggProduct.product_id
        ? aggProduct.product_id
        : productSelected),

      label: handleLabelSubmit(label),
      list_uuid: UUIDList,
    };

    setLoadingsubmit(true);
    aggregate(dataSubmit)
      .then((result) => {
        setAlert({
          show: true,
          message: "Aggregate Successfully",
          severity: "success",
        });
        setQrList([]);
        setSubmitedUUID([]);
        setLoadingsubmit(false);
        setDataPrint(result);
        return;
      })
      .catch((error) => {
        setLoadingsubmit(false);
        setAlert({
          show: true,
          message: error.response.data.Detail,
          severity: "error",
        });
      });
  };

  //watch form validate
  // const handleValidateForm = () => {
  //   console.log("berubah");
  //   if (productSelected !== null && qty > 0) {
  //     setValidation(false);
  //     return;
  //   }

  //   setValidation(true);
  // };

  /* --------------------------------- mounted -------------------------------- */
  useEffect(() => {
    //fetch product
    fetchProduct()
      .then((result) => {
        setProducts(result);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  }, []);

  /* -------------------------- watch form validation ------------------------- */
  useEffect(() => {
    if (productSelected !== null && qty > 0) {
      setValidation(false);
      return;
    }

    setValidation(true);
  }, [productSelected, qty]);

  /* ---------------------- watch reach quantity and push --------------------- */
  useEffect(() => {
    if (autoScan === true) {
      if (qty > 0 && qty === qrList.length) {
        //SUBMIT AGGREGATION
        handleSubmit();
        setQrList([]);
        setSubmitedUUID([]);

        //QR AGGREGATION COME OUT
        return;
      }
      return;
    }
  }, [handleSubmit, qrList, qty, autoScan]);

  /* --------------------- watch empty qr list or agg list -------------------- */
  useEffect(() => {
    if (aggregationList.length === 0 && qrList.length === 0) {
      setAggProduct({ name: "", product_id: 0 });
    }
  }, [qrList, aggregationList]);

  return {
    handleSelectedProduct,
    dataPrint,
    aggProduct,
    setAggProduct,
    scanFocus,
    setUuid,
    uuid,
    handleAddUuid,
    uuidCheckLoading,
    qrList,
    handleStopAggregate,
    handleStartAggregate,
    validation,
    label,
    setLabel,
    qty,
    setQty,
    autoScan,
    handleChangeProductSelected,
    products,
    productSelected,
    startAggregate,
    handleOnClose,
    alert,
    setAlert,
    aggregationList,
    setAggregationList,
    handleRemove,
    handleRemoveList,
  };
};

export default useAggregate;
