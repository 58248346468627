//material
import { Typography, Grid, AppBar, Toolbar } from "@mui/material";

//icon
import AccountCircle from "@mui/icons-material/AccountCircle";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

//hook
import useSetting from "@/store/hook/setting";
import useAppBar from "@/store/hook/app_bar";

//delivery
import { Settings } from "@/delivery/pages/Admin/Settings/Settings";
import { useEffect, useState } from "react";
import { getProfile } from "@/infrastructure/utils/auth_util";

export function AppBarTop() {
  const { handleMenu, handleLogout, handleClose, anchorEl, auth } = useAppBar();

  const { handleShowDialogSettingAdmin, dialogSetting } = useSetting();
  const [profile, setProfile] = useState<any>();

  //get data from jwt
  useEffect(() => {
    let dataProfile: any = getProfile();
    setProfile(dataProfile);
  }, []);

  return (
    <div>
      <Settings show={dialogSetting} onClose={handleShowDialogSettingAdmin} />
      <AppBar
        position="fixed"
        sx={{ flexGrow: 1, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Grid sx={{ mr: 2 }}>
            <img
              src={
                "https://urbangroup.co.id/wp-content/uploads/2020/03/urban-company-logo-putih.png"
              }
              width={"100px"}
              alt={"Urban Company"}
            />
          </Grid>
          <Typography
            variant="subtitle1"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            | Aggregation
          </Typography>
          <Typography variant="subtitle1" noWrap component="div">
            {profile?.name ? profile?.name : profile?.preferred_username}
          </Typography>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
        {auth && (
          <div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleShowDialogSettingAdmin}>
                Setting
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </AppBar>
    </div>
  );
}
