const CryptoJS = require("crypto-js");
// import { AES } from "crypto-js";
// const AES = require("crypto-js/aes");
// import * as crypto from "crypto-js";

const kunci = "u12b4n_";

export class Crypt {
  public static encrypt(data: any) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), kunci).toString();
  }

  public static decrypt(data: any) {
    let dect = CryptoJS.AES.decrypt(data, kunci);
    let dectResult = JSON.parse(dect.toString(CryptoJS.enc.Utf8));
    return dectResult;
  }
}
