import { Product } from "@domain/model/product";
import { API_MS_ITEM } from "@/domain/constanta/setting";
import axiosClient from "@/infrastructure/axios/axios";

//fetch product
export async function fetchProduct() {
  let res = await axiosClient({
    baseURL: API_MS_ITEM,
    method: "POST",
    url: `${API_MS_ITEM}/item/fetch`,
  });

  return Product.fromJsonList(res.data);
}

export async function getProduct(data: any) {
  let res = await axiosClient({
    baseURL: API_MS_ITEM,
    method: "POST",
    url: `/item/get`,
    data: data,
  });

  return Product.fromJson(res.data);
}
