//react
import React from "react";

//component
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";

//material
import {
  Grid,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";

//icon
import { VisibilityOff, Visibility } from "@mui/icons-material";

//hook
import useAuth from "@/store/hook/auth";

export function Login() {
  const { values, setValues, submitLogin } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div
      style={{
        backgroundImage: `url("https://urbangroup.co.id/wp-content/uploads/2022/09/flat-mountains.png")`,
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      }}
    >
      <Grid
        item
        container
        sx={{ px: 3, py: 3 }}
        style={{ minHeight: "100vh" }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
        <Grid item justifySelf={"center"} xs={12} sm={4} md={4} lg={4}>
          <MsSnackBar
            severity="error"
            open={values.snackbar}
            message={values.message}
          />
          <Grid item container justifyContent={"center"}>
            <img
              src={
                "https://urbangroup.co.id/wp-content/uploads/2020/03/urban-company-logo.png"
              }
              width={"200px"}
              alt={"Urban Company"}
            />
          </Grid>

          <Typography align="center" sx={{ my: 3 }} variant="h6">
            Aggregation
          </Typography>
          {/*username*/}
          <Grid sx={{ my: 1 }} item md={12} lg={12} xs={12}>
            <TextField
              style={{ backgroundColor: "#fff" }}
              value={values.username}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setValues({ ...values, username: e.target.value })
              }
              label="Username"
              fullWidth
              id="fullWidth"
            ></TextField>
          </Grid>

          {/*password*/}
          <Grid sx={{ my: 1 }} item xs={12} md={12} lg={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                style={{ backgroundColor: "#fff" }}
                value={values.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setValues({ ...values, password: e.target.value })
                }
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    submitLogin();
                    ev.preventDefault();
                  }
                }}
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
          </Grid>

          {/*button login*/}
          <Grid item xs={12} md={12} lg={12}>
            <LoadingButton
              loading={values.loading}
              loadingPosition="center"
              sx={{ my: 2 }}
              fullWidth
              variant="contained"
              disableElevation
              onClick={submitLogin}
            >
              Login
            </LoadingButton>
            {/* <p>IP/Domain Server: 172.100.444.16</p> */}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}></Grid>
      </Grid>
    </div>
  );
}
