import * as React from "react";
import Alert, { AlertColor } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

interface MsSnackBarProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  duration?: number;
  onClose?: (event: Event | React.SyntheticEvent<any, Event>) => void;
}

export function MsSnackBar(props: MsSnackBarProps) {
  const [open, setOpen] = React.useState(props.open);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={props.open}
      autoHideDuration={props.duration ? props.duration : 2000}
      onClose={props.onClose}
    >
      <Alert
        onClose={props.onClose}
        severity={props.severity}
        sx={{ width: "100%" }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  );
}
