import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import React, { useState } from "react";

interface TextFieldQrProps {
  helperText?: string;
  label: string;
  onKeyEnter?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  variant?: "outlined" | "standard" | "filled" | undefined;
  disabled?: boolean;
  spaceY?: boolean;
  spaceX?: boolean;
  autofocus?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
}

export function MsTextField(props: TextFieldQrProps) {
  return (
    <div>
      <FormControl
        sx={{ py: props.spaceY ? 0.5 : null, px: props.spaceX ? 0.5 : null }}
        size="small"
        fullWidth
      >
        {/* <InputLabel htmlFor="component-outlined">UUID</InputLabel> */}
        <TextField
          inputRef={
            props.autofocus === true
              ? (input) => input && input.focus()
              : () => null
          }
          autoFocus={props.autofocus}
          variant={props.variant ? props.variant : "outlined"}
          size="small"
          id="component-outlined"
          label={props.label}
          value={props.value}
          onChange={props.onChange}
          onKeyDown={props.onKeyEnter}
          disabled={props.disabled}
        />
        {props.helperText ? (
          <FormHelperText id="component-helper-text">
            {props.helperText}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormControl>
    </div>
  );
}
