//react
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//infrastructure
import { getProfile, removeToken } from "@/infrastructure/utils/auth_util";

const useAppBar = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profile, setProfile] = useState<any>();

  const navigate = useNavigate();
  //get data from jwt
  useEffect(() => {
    let dataProfile: any = getProfile();
    setProfile(dataProfile);
  }, []);

  //logout
  const handleLogout = () => {
    removeToken();
    navigate("/login");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return {
    handleMenu,
    handleLogout,
    handleClose,
    anchorEl,
    auth,
    profile,
  };
};

export default useAppBar;
