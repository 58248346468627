//material
import { Card, CardContent, Typography, IconButton, Grid } from "@mui/material";
import { Box } from "@mui/system";

//icons
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//infrastructure
import shortCharacter from "@/infrastructure/utils/short_character";

interface ListAggregationProps {
  items?: [] | any | undefined;
  uuid?: string;
  product?: { product_id: number; name: string } | undefined;
  onClickRemoveList: (
    index: React.MouseEventHandler<HTMLButtonElement>
  ) => void | undefined;
}

export function ListAggregation(props: ListAggregationProps) {
  //get total product on aggrated
  const getTotal = () => {
    let totalCount = [];
    for (let i = 0; i < props.items.length; i++) {
      totalCount.push(props.items[i].actualTotal);
    }
    return totalCount;
  };

  const sumTotal = getTotal().reduce((accumulator, current) => {
    return accumulator + current;
  }, 0);

  return (
    <div>
      {sumTotal > 0 ? (
        <p>
          Product: <b>{props.product?.name}</b>
          &nbsp; | &nbsp; Product Count: <b>{sumTotal}</b>
        </p>
      ) : (
        ""
      )}

      {props.items.map((item: any, index: any) => (
        <Grid container>
          <Grid item md={11} sm={11} lg={11}>
            <Card
              key={index}
              variant="outlined"
              sx={{
                border: 1,
                borderColor: "#8c8c8b",
                maxWidth: "100%",
                mt: 1,
              }}
            >
              <CardContent>
                <Grid
                  sx={{
                    border: 1,
                    borderColor: "#cfcfcf",
                    borderRadius: "5px",
                    mb: 1,
                    px: 1,
                    py: 1,
                  }}
                  container
                >
                  <Typography variant="subtitle2" component="div">
                    {item.aggregation.label}
                  </Typography>
                </Grid>
                <Grid
                  sx={{
                    border: 1,
                    borderColor: "#cfcfcf",
                    borderRadius: "5px",
                    mb: 1,
                  }}
                  container
                >
                  <Grid
                    sx={{ borderColor: "#b1b1b1" }}
                    display={"flex"}
                    justifyContent="start"
                    md={8}
                    px={1}
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="subtitle2"
                      component="div"
                    >
                      ID: {shortCharacter(item.aggregation.UUID, 13)}
                    </Typography>
                  </Grid>
                  <Grid
                    md={2}
                    sx={{ borderLeft: 1, borderColor: "#b1b1b1" }}
                    display={"flex"}
                    justifyContent="center"
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="subtitle2"
                      component="div"
                    >
                      Qty: {item.aggregation.qty}
                    </Typography>
                  </Grid>
                  <Grid
                    md={2}
                    sx={{
                      borderLeft: 1,
                      borderColor: "#b1b1b1",
                    }}
                    display={"flex"}
                    justifyContent="center"
                  >
                    <Typography
                      sx={{ py: 1 }}
                      variant="subtitle2"
                      component="div"
                    >
                      Level: {item.aggregation.level}
                    </Typography>
                  </Grid>
                </Grid>

                {/* <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableBody>
                    {item.aggregationQr.map((item: any, index: any) => (
                      <TableRow key={index}>
                        <TableCell align="left">{item.qrUUID}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table> */}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={1} sm={1} lg={1}>
            <Box display={"flex"} justifyContent="end" alignSelf={"center"}>
              <IconButton
                size="small"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => props.onClickRemoveList(index)}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
