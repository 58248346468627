import { BaseModel } from "./base_model";
import { Product } from "./product";
import { AggregationHistory } from "./aggregationHistory";
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";
import { AggregationQr } from "./aggregationQr";

export class Aggregation extends BaseModel {
  qty: number;
  label: string;
  userId: string;
  productId: string;
  aggregationParentUUID?: string;
  aggregationRefUUID?: string;
  status: string;
  level: number;
  subAggregation: Aggregation[];
  product?: Product[];
  aggregationHistory?: AggregationHistory[];
  aggregationQr: AggregationQr[];

  constructor(
    ID: bigint,
    UUID: string,
    createdAt: string,
    updateAt: string,
    qty: number,
    label: string,
    userId: string,
    productId: string,
    aggregationParentUUID: string,
    aggregationRefUUID: string,
    status: string,
    level: number,
    subAggregation: [],
    product: Product[],
    aggregationHistory: AggregationHistory[],
    aggregationQr: AggregationQr[]
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.subAggregation = subAggregation;
    this.qty = qty;
    this.label = label;
    this.userId = userId;
    this.productId = productId;
    this.aggregationParentUUID = aggregationParentUUID;
    this.aggregationRefUUID = aggregationRefUUID;
    this.status = status;
    this.level = level;
    this.product = product;
    this.aggregationHistory = aggregationHistory;
    this.aggregationQr = aggregationQr;
  }

  get getCreatedAtTimeFormat(): string | null {
    try {
      return changeTimeFormat(this.createdAt);
    } catch {
      return null;
    }
  }

  get getDataBatch(): any {
    interface ResultItem {
      productionCode: string;
      qty: number;
      expiredDate: string;
    }
    if (this.aggregationQr.length > 0) {
      const codeQtyMap: { [key: string]: ResultItem } = {};

      this.aggregationQr.forEach((item) => {
        const key = `${item.productionCode}-${item.expiredDate}`;

        if (codeQtyMap[key]) {
          codeQtyMap[key].qty++;
        } else {
          codeQtyMap[key] = {
            productionCode: item.productionCode,
            expiredDate: item.expiredDate,
            qty: 1,
          };
        }
      });

      const result: ResultItem[] = Object.values(codeQtyMap);
      return result;
    }
  }

  public static fromJsonToList(data: any): Aggregation[] | any {
    //if params null
    if (data == null || data === undefined) {
      return null;
    }

    //if params not null
    let listAggregation: Aggregation[] = [];
    for (let i = 0; i < data.length; i++) {
      listAggregation.push(Aggregation.fromJson(data[i]));
    }
    return listAggregation;
  }

  public static fromJson(data: any): Aggregation | any {
    try {
      return new Aggregation(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.qty,
        data.label,
        data.user_id,
        data.product_id,
        data.aggregation_parent_uuid,
        data.aggregation_ref_uuid,
        data.status,
        data.level,
        data.sub_aggregation
          ? Aggregation.fromJsonToList(data.sub_aggregation)
          : [],
        data.product ? Product.fromJson(data.product) : null,
        data.aggregation_history
          ? AggregationHistory.fromJsonToList(data.aggregation_history)
          : [],
        data.aggregation_qr
          ? AggregationQr.fromJsonList(data.aggregation_qr)
          : []
      );
    } catch {
      return null;
    }
  }
}
