export const SIGN_KEY = "external-endpoint";

export const QR_URL = process.env.REACT_APP_QR_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_MS_SECURE = process.env.REACT_APP_API_MS_SECURE;
export const API_MS_ITEM = process.env.REACT_APP_API_MS_ITEM;
export const API_MS_USER = process.env.REACT_APP_API_MS_USER;

export const API_AUTH = process.env.REACT_APP_API_AUTH;
export const REALM = process.env.REACT_APP_REALM;

export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const GRANT_TYPE = process.env.REACT_APP_GRANT_TYPE;

export const QR_BALI = "QR_BALI";
export const AGG_BALI = "AGG_BALI";
export const QR_MALANG = "QR_MALANG";
export const AGG_MALANG = "AGG_MALANG";
