import { BaseModel } from "./base_model";

export class AggregationQr extends BaseModel {
  aggregationUUID: string;
  qrUUID: string;
  productID: string;
  productionCode: string;
  expiredDate: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    aggregationUUID: string,
    qrUUID: string,
    productID: string,
    productionCode: string,
    expiredDdate: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.aggregationUUID = aggregationUUID;
    this.qrUUID = qrUUID;
    this.productID = productID;
    this.productionCode = productionCode;
    this.expiredDate = expiredDdate;
  }

  public static fromJsonList(data: any): AggregationQr[] {
    let aggreagtionQrList: AggregationQr[] = [];
    for (let i = 0; i < data.length; i++) {
      aggreagtionQrList.push(AggregationQr.fromJson(data[i]));
    }
    return aggreagtionQrList;
  }

  public static fromJson(data: any): AggregationQr {
    return new AggregationQr(
      data.id,
      data.uuid,
      data.created_at,
      data.update_at,
      data.aggregation_uuid,
      data.qr_uuid,
      data.product_id,
      data.production_code,
      data.expired_date
    );
  }
}
