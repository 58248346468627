import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";

//pages Aggregation
import { Main } from "@delivery/pages/Admin/Main";
import { Login } from "@delivery/pages/Login/Login";

//pages Aggregation
import { AggregationList } from "@/delivery/pages/Admin/AggregationList/AggregationList";
import { QrAggregation } from "@delivery/pages/Admin/QrAggregation/QrAggregation";
import { Association } from "@delivery/pages/Admin/Association/Association";
import { Aggregate } from "@delivery/pages/Admin/Aggregate/Aggregate";
import { AggregatePack } from "@delivery/pages/Admin/Aggregate/AggregatePack";
import { Detach } from "@delivery/pages/Admin/Detach/Detach";
import { AggregationToPrint } from "@/delivery/pages/Admin/AggregationList/AggregateToPrint";
import { AggregatePrintLabel } from "@delivery/pages/Admin/Aggregate/AggregatePrintLabel";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/aggregate-print-label" element={<AggregatePrintLabel />} />
      <Route path="/aggregate-print" element={<AggregationToPrint />} />
      <Route path="/admin" element={<Main />}>
        <Route path="/admin/aggregate-pack" element={<AggregatePack />} />
        <Route path="/admin/aggregate" element={<Aggregate />} />
        <Route path="/admin/aggregation-list" element={<AggregationList />} />
        <Route path="/admin/qr-aggregation" element={<QrAggregation />} />
        <Route path="/admin/association" element={<Association />} />
        <Route path="/admin/detach" element={<Detach />} />
      </Route>
    </Routes>
  );
}
export default App;
