//react
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//material
import { Grid, Typography } from "@mui/material";

//delivery
import { fetchQrAssociation } from "@repository/api/qrAggregation";
import { MsBarcode } from "@delivery/components/Barcode/Barcode";
import { ListQr } from "@/delivery/pages/Admin/Aggregate/ListQr";
import { MsTemplatePrint } from "@/delivery/components/TemplatePrint/TemplatePrint";

//infrastructure
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

//model
import { Aggregation } from "@domain/model/aggregation";
import { AggregationQr } from "@domain/model/aggregationQr";

export function AggregationToPrint() {
  const navigate = useNavigate();
  const location: any = useLocation();
  let uuid = location.state.uuid;

  const [aggregation, setAggregation] = useState<Aggregation>();
  const [qrAggregation, setQrAggregation] = useState<AggregationQr[] | any>();
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    setIsData(false);
    fetchQrAssociation(uuid)
      .then((result) => {
        setAggregation(result.aggregation);
        setQrAggregation(result.aggregationQr);
        setIsData(true);
      })
      .catch((err) => {});
  }, [uuid]);

  useEffect(() => {
    if (isData === true) {
      window.print();
      window.onfocus = function () {
        window.close();
        navigate("/admin/aggregation-list", { replace: true });
      };
    }
  }, [isData, navigate]);

  // React.forwardRef((props: any, ref: any) => {
  return (
    <div>
      {/* <Grid sx={{ border: 1, borderRadius: "7px" }} container> */}
      {/* <MsTemplatePrint aggregation={aggregation} /> */}
      {/* </Grid> */}
    </div>
  );
  // });
}
