import { BaseModel } from "./base_model";

export class AggregationHistory extends BaseModel {
  aggregationAction: string;
  aggregationUUID: string;
  aggregationRefUUID?: string;
  userID: string;
  notes?: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    aggregationAction: string,
    aggregationUUID: string,
    aggregationRefUUID: string,
    userID: string,
    notes: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.aggregationAction = aggregationAction;
    this.aggregationUUID = aggregationUUID;
    this.aggregationRefUUID = aggregationRefUUID;
    this.userID = userID;
    this.notes = notes;
  }

  public static fromJsonToList(data: any): AggregationHistory[] | any {
    //if params null
    if (data == null || data === undefined) {
      return null;
    }

    //if params not null
    let listAggregation: AggregationHistory[] = [];
    for (let i = 0; i < data.length; i++) {
      listAggregation.push(AggregationHistory.fromJson(data[i]));
    }
    return listAggregation;
  }

  public static fromJson(data: any): AggregationHistory | any {
    try {
      if (data == null || data === undefined) {
        return null;
      }
      return new AggregationHistory(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.aggregation_action,
        data.aggregation_uuid,
        data.aggregation_ref_uuid,
        data.notes,
        data.user_id
      );
    } catch {
      return null;
    }
  }
}
