//react
import * as React from "react";

//material
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
} from "@mui/material";

//model
import { AggregationHistory } from "@domain/model/aggregationHistory";

//infrastructure
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

interface TableAggregationProps {
  items: AggregationHistory[] | undefined | any;
  onChangePaginate?: (event: React.ChangeEvent<unknown>, page: number) => void;
  rowsPerPage?: number;
  paginateCount?: number;
}

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [
  createColumn(1, "Aggregation UUID"),
  createColumn(2, "Action"),
  createColumn(3, "User ID"),
  createColumn(4, "Notes"),
  createColumn(5, "Created at"),
];

export function TableAggregationHistory(props: TableAggregationProps) {
  return (
    <div>
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item: any) => (
              <TableRow key={item}>
                <TableCell align="left">{item.aggregationUUID}</TableCell>
                <TableCell align="left">{item.aggregationAction}</TableCell>
                <TableCell align="left">{item.userID}</TableCell>
                <TableCell align="left">{item.notes}</TableCell>
                <TableCell align="left">
                  {changeTimeFormat(item.createdAt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack sx={{ py: 2 }} spacing={2}>
        {/* <Pagination
          onChange={props.onChangePaginate}
          count={props.paginateCount}
          defaultPage={1}
          siblingCount={0}
        /> */}
      </Stack>
    </div>
  );
}
