import { MsTemplatePrint } from "@/delivery/components/TemplatePrint/TemplatePrint";

//react
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//material
import { Grid } from "@mui/material";

//delivery
import { fetchQrAssociation } from "@repository/api/qrAggregation";
import { MsBarcode } from "@delivery/components/Barcode/Barcode";
import { ListQr } from "@/delivery/pages/Admin/Aggregate/ListQr";

//infrastructure
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";

//model
import { Aggregation } from "@domain/model/aggregation";
import { AggregationQr } from "@domain/model/aggregationQr";
import { Product } from "@/domain/model/product";
import { getProduct } from "@/repository/api/product";
import { CheckQrModel } from "@/domain/model/checkQr";

//interface
import DataPrintAgg from "@domain/interfaces/dataPrintAgg";

export function AggregatePrintLabel() {
  const navigate = useNavigate();
  const location: any = useLocation();
  let uuid = location.state.uuid;

  const [aggregation, setAggregation] = useState<Aggregation>();
  const [dataPrint, setDataPrint] = useState<DataPrintAgg>();
  const [qrAggregation, setQrAggregation] = useState<AggregationQr[] | any>();
  const [isData, setIsData] = useState(false);

  useEffect(() => {
    setIsData(false);
    fetchQrAssociation(uuid)
      .then((result) => {
        let productParam = {
          id: result.aggregation.productId,
        };
        //show product
        getProduct(productParam)
          .then((result) => {
            // let dataPrintProductAgg: DataPrintAgg = {
            //   aggregation: result.aggregation,
            //   product: result,
            //   qr:
            // };
            // setDataPrint(dataPrintProductAgg);

            setAggregation(result.aggregation);
            setQrAggregation(result.aggregationQr);
            setIsData(true);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, [uuid]);

  useEffect(() => {
    if (isData === true) {
      window.print();
      window.onfocus = function () {
        window.close();
        navigate("/admin/aggregate", { replace: true });
      };
    }
  }, [isData, navigate]);
  return (
    // <Grid sx={{ border: 1, borderRadius: "7px" }} container>
    <MsTemplatePrint data={dataPrint} />
    // </Grid>
  );
}
