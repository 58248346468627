import { Crypt } from "@/infrastructure/utils/crypt";
import jwt_decode from "jwt-decode";

export function setToken(data: any) {
  let en = Crypt.encrypt(data);
  localStorage.setItem("u12", en);
}

export function getToken() {
  try {
    // return localStorage.getItem("u12");
    let dec = localStorage.getItem("u12");
    return Crypt.decrypt(dec);
  } catch {
    return null;
  }
}

export function getProfile() {
  if (getToken()) {
    return jwt_decode(getToken());
  }
}

export function removeToken() {
  localStorage.removeItem("u12");
}
