//material
import { Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

//delivery
import { MsTextField } from "@delivery/components/TextField/TextField";
import { CardAggregation } from "@delivery/pages/Admin/QrAggregation/CardAggregation";
import { TableQrAggregationList } from "@delivery/pages/Admin/QrAggregation/TableQrAggregationList";
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";

//hook
import useQrAggregationCheck from "@/store/hook/qrAggregationCheck";

export function QrAggregation() {
  const {
    alert,
    handleOnClose,
    uuid,
    setUUID,
    handleScan,
    loadingShowData,
    isShow,
    exportLoading,
    handleExportQrAggregation,
    aggregation,
    qrAggregations,
  } = useQrAggregationCheck();

  return (
    <Grid sx={{ width: "100%" }} item container>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnClose}
      />
      <Grid sx={{ py: 1 }}>
        <Typography variant="h5">Check QR Aggregation</Typography>
      </Grid>
      <Grid item container sx={{ py: 2 }}>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <MsTextField
            autofocus
            helperText="put cursor here to scan"
            value={uuid}
            label="Qr Code"
            onChange={(e) => setUUID(e.target.value)}
            onKeyEnter={(ev) => {
              if (ev.key === "Enter") {
                handleScan();
                ev.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <Box display={"flex"} justifyContent="start" alignSelf={"center"}>
            <LoadingButton
              loading={loadingShowData}
              loadingPosition="center"
              variant="contained"
              disableElevation
              onClick={handleScan}>
              Search
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      {isShow ? (
        <Grid sx={{ width: "100%" }}>
          <Grid item container sx={{ py: 1 }}>
            <Grid item xs={12} sm={12} md={6} sx={{ px: 1 }}>
              <Grid item container>
                <Grid item md={6}>
                  <Typography variant="h6">Aggregation</Typography>
                </Grid>
                <Grid item md={6}>
                  <Box
                    display={"flex"}
                    justifyContent="end"
                    alignSelf={"center"}>
                    <LoadingButton
                      loading={exportLoading}
                      loadingPosition="center"
                      variant="contained"
                      disableElevation
                      size="small"
                      onClick={() =>
                        handleExportQrAggregation(aggregation?.UUID)
                      }>
                      Export
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container sx={{ py: 1 }}>
            <Grid item xs={12} sm={12} md={6} sx={{ px: 1 }}>
              <CardAggregation items={aggregation} />
            </Grid>
          </Grid>
          <Grid item container sx={{ py: 2 }}>
            <Grid item xs={12} sm={12} md={8} sx={{ px: 1 }}>
              <TableQrAggregationList items={qrAggregations} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
