import { Outlet } from "react-router-dom";
import { AppBarTop } from "../../components/Layout/AppBar/AppBar";
import { DrawerSide } from "../../components/Layout/Drawer/Drawer";
import Box from "@mui/material/Box";
import printBarcodeHook from "@/store/hook/printBarcodeAggregate";

export function Main() {
  const PrintPageProvider = printBarcodeHook.Provider;
  return (
    <Box sx={{ display: "flex" }}>
      <AppBarTop />
      <DrawerSide />
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 10 }}>
        <div className="">
          <PrintPageProvider>
            <Outlet />
          </PrintPageProvider>
        </div>
      </Box>
    </Box>
  );
}
