import {
  AGG_BALI,
  AGG_MALANG,
  QR_BALI,
  QR_MALANG,
} from "@/domain/constanta/setting";

const checkQr = (val: string) => {
  let qrBaliReg = new RegExp("^((https?://)?qr.mslink.id/)[^_W]{32}");
  let aggBaliReg = new RegExp("^[^_W]{32}");
  let qrMalangReg = new RegExp("^((https?://)?MSGL.IO/)[^_W]{8}");
  let aggMalangReg = new RegExp("(^[^W]{22}-[^W]{32})");

  if (qrBaliReg.test(val)) {
    return QR_BALI;
  }
  if (aggBaliReg.test(val)) {
    return AGG_BALI;
  }
  if (qrMalangReg.test(val)) {
    return QR_MALANG;
  }
  if (aggMalangReg.test(val)) {
    return AGG_MALANG;
  }
};

export default checkQr;
