const getRandomCharacters = (numbChar: number, characters: string): string => {
  let result = "";

  for (let x = 0; x < numbChar; x++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

const getSecondDate = () => {
  const currentDate = new Date();

  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const second = currentDate.getSeconds();

  return `${hours}${minutes}${second}`;
};

const handleLabelAggregate = () => {
  const characters = "ABCDEFGHJKLMNOPQRSTUVWXYZ";

  const letterChar = getRandomCharacters(4, characters);
  const numberChar = getSecondDate();

  return `${letterChar}/${numberChar}`;
};

export default handleLabelAggregate;
