//material
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";

//icon
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

//react
import React from "react";

//utility
import shortCharacter from "@/infrastructure/utils/short_character";

interface ListQrProps {
  show?: boolean;
  items?: any | [];
  product?: { product_id: number; name: string } | undefined;
  onClickRemove: (
    index: React.MouseEventHandler<HTMLButtonElement>
  ) => void | undefined;
}

let a = [
  {
    UUUID: "12345",
    serialCode: "AABB",
  },
  {
    UUUID: "54321",
    serialCode: "BBAAA",
  },
  {
    UUUID: "12345",
    serialCode: "AABB",
  },
];

export function ListQr(props: ListQrProps) {
  return (
    <div>
      {props.items.length > 0 ? (
        <p>
          Product: <b>{props.product?.name}</b>
          &nbsp; | &nbsp; Product Count: <b>{props.items.length}</b>
        </p>
      ) : (
        ""
      )}

      {props.items.map((item: any, index: any) => (
        <Grid container>
          <Grid xs={1} item sx={{ display: "flex", alignItems: "center" }}>
            {index + 1}
          </Grid>
          <Grid xs={11} item>
            {" "}
            {/*  this Grid */}
            <Card
              style={{
                border: item.isDuplicate ? "1px solid red" : "",
              }}
              key={index}
              variant="outlined"
              sx={{ py: 1, px: 1, my: 0.5, maxWidth: "100%" }}>
              <Grid container>
                <Grid
                  sx={{ display: "flex", alignItems: "center" }}
                  item
                  md={10}
                  sm={10}
                  lg={10}>
                  <Box
                    sx={{ alignItems: "center" }}
                    display={"flex"}
                    justifyContent="start"
                    alignItems={"center"}>
                    <Typography variant="body2" color="text.secondary">
                      SERIAL ID: <b>{item.serialCode}</b>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={2} sm={2} lg={2}>
                  <Box
                    display={"flex"}
                    justifyContent="end"
                    alignSelf={"center"}>
                    <IconButton
                      size="small"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={() => props.onClickRemove(index)}>
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ))}
    </div>
  );
}
