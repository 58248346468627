export class BaseModel {
  ID: BigInt;
  UUID: string;
  createdAt: string;
  updateAt: string;

  constructor(ID: BigInt, UUID: string, createdAt: string, updateAt: string) {
    this.ID = ID;
    this.UUID = UUID;
    this.createdAt = createdAt;
    this.updateAt = updateAt;
  }
}
