//react
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//repository
import { login } from "@repository/api/auth";

//utility
import { setToken } from "@/infrastructure/utils/auth_util";

//constanta
import {
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE,
} from "@/domain/constanta/setting";

const useAuth = () => {
  const [values, setValues] = useState({
    username: "",
    password: "",
    loading: false,
    snackbar: false,
    message: "",
  });
  const navigate = useNavigate();

  //submit Login
  const submitLogin = () => {
    setValues({ ...values, loading: !values.loading });
    let data = {
      client_id: CLIENT_ID,
      username: values.username,
      password: values.password,
      grant_type: GRANT_TYPE,
      client_secret: CLIENT_SECRET,
    };
    login(data)
      .then((res) => {
        setToken(res.data.access_token);
        // localStorage.setItem("token", res.data.access_token);
        navigate("/admin/aggregate");
        window.location.reload();
        setValues({ ...values, loading: !values.loading });
      })
      .catch((error) => {
        setValues({
          ...values,
          loading: !values.loading,
          snackbar: true,
          message: error.response.data.error_description,
        });
      });
  };
  return {
    values,
    setValues,
    submitLogin,
  };
};

export default useAuth;
