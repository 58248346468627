//material
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

//delivery
import { TableAgregation } from "@/delivery/pages/Admin/AggregationList/TableAggregation";
import { MsTextField } from "@/delivery/components/TextField/TextField";
import { MsSnackBar } from "@/delivery/components/SnackBar/SnackBar";

//hook
import useAggregationList from "@/store/hook/aggregationList";
import printBarcodeHook from "@/store/hook/printBarcodeAggregate";

export function AggregationList() {
  const PrintPageProvider = printBarcodeHook.Provider;
  const {
    handleSearch,
    aggregations,
    handleChangePage,
    handleRowsPerPageChange,
    loadingData,
    page,
    rowsPerPage,
    totalCount,
    setSearchField,
    searchField,
    alert,
    handleOnClose,
  } = useAggregationList();

  return (
    <PrintPageProvider>
      <div>
        {/* <p>{aggregations}</p> */}
        <MsSnackBar
          message={alert.message}
          open={alert.show}
          severity={alert.severity}
          duration={3000}
          onClose={handleOnClose}
        />
        <Grid>
          <Grid sx={{ py: 1 }}>
            <Typography variant="h5">Aggregation List</Typography>
          </Grid>

          <br />

          <Grid sx={{ py: 1 }}>
            <Grid sx={{ mb: 3 }} container>
              {/* uuid search */}
              <Grid item sm={4} md={4} lg={4}>
                <MsTextField
                  helperText="put cursor here to scan to search"
                  value={searchField.uuid}
                  label={"Qr Code"}
                  onChange={(e) =>
                    setSearchField({ ...searchField, uuid: e.target.value })
                  }
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      handleSearch();
                      ev.preventDefault();
                    }
                  }}
                />
              </Grid>
              {/* label */}
              <Grid item sx={{ px: 0.5 }} sm={4} md={4} lg={4}>
                <TextField
                  fullWidth
                  label={"Label"}
                  size={"small"}
                  onChange={(e) =>
                    setSearchField({ ...searchField, label: e.target.value })
                  }
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      handleSearch();
                      ev.preventDefault();
                    }
                  }}
                />
              </Grid>

              {/* search button */}
              <Grid item sm={2} md={2} lg={2}>
                <Button
                  disableElevation
                  onClick={handleSearch}
                  size="medium"
                  sx={{ ml: 1 }}
                  variant="contained"
                  color="primary">
                  <SearchIcon />
                </Button>
              </Grid>
              {/* product */}
              {/* <Grid item sx={{ px: 0.5 }} sm={2} md={2} lg={2}>
              <MsSelect
                value={productSelected}
                size="small"
                items={products}
                label="Product"
                onChange={handleChangeProductSelected}
              />
            </Grid> */}
            </Grid>
            <TableAgregation
              loading={loadingData}
              items={aggregations}
              totalCount={totalCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={(e) => {
                handleRowsPerPageChange(e);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </PrintPageProvider>
  );
}
