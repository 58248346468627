//react
import React from "react";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

//material
import {
  Box,
  Drawer,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Grid,
} from "@mui/material";

//icon
import ListAltIcon from "@mui/icons-material/ListAlt";
import QrCodeIcon from "@mui/icons-material/QrCode";
import JoinInnerIcon from "@mui/icons-material/JoinInner";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import HeartBrokenOutlinedIcon from "@mui/icons-material/HeartBrokenOutlined";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const drawerWidth = 240;

interface DrawerProps {
  label: string;
  link: string;
  icon: any;
  children?: DrawerProps[] | undefined;
  rules?: string[];
}

export function DrawerSide() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);

  const handleClick = (item: any) => {
    if (item.children) {
      setOpen(!open);
    }
  };

  const handleClickSubMenu = (item: any) => {
    if (item.link === location.pathname) {
      setOpen(true);
      return;
    }
    setOpen(false);
  };

  let menu: DrawerProps[] = [
    {
      label: "Aggregate",
      link: "#",
      icon: <JoinInnerIcon />,
      rules: [
        "ms-aggregation.access_aggregate",
        "ms-aggregation.access_fetch_aggregation",
        "ms-aggregation.access_fetch_qr",
      ],
      children: [
        {
          label: "Product",
          link: "/admin/aggregate",
          icon: <CategoryOutlinedIcon />,
        },
        {
          label: "Pack",
          link: "/admin/aggregate-pack",
          icon: <Inventory2OutlinedIcon />,
        },
      ],
    },
    {
      label: "Aggregation List",
      link: "/admin/aggregation-list",
      icon: <ListAltIcon />,
      rules: [
        "ms-aggregation.access_fetch_aggregation",
        "ms-aggregation.access_fetch_qr",
      ],
    },
    {
      label: "QR Aggregation",
      link: "/admin/qr-aggregation",
      icon: <QrCodeIcon />,
      rules: [
        "ms-aggregation.access_get_aggregation",
        "ms-aggregation.access_export",
      ],
    },
    {
      label: "Association",
      link: "/admin/association",
      icon: <AccountTreeOutlinedIcon />,
      rules: ["ms-aggregation.access_get_aggregation"],
    },
    {
      label: "Detach",
      link: "/admin/detach",
      icon: <HeartBrokenOutlinedIcon />,
      rules: ["ms-aggregation.access_detach"],
    },
  ];

  return (
    <Drawer
      open={false}
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List>
          {menu.map((item, index) => (
            <NavLink
              key={index}
              onClick={() => handleClick(item)}
              style={{
                backgroundColor: "#000000",
                color: "#000000",
                textDecoration: "none",
              }}
              to={item.link}
            >
              <ListItem disablePadding>
                <ListItemButton selected={item.link === location.pathname}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                  {item.children ? (
                    <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
                  ) : (
                    ""
                  )}
                </ListItemButton>
              </ListItem>

              {item.children?.map((itemSub, indexSub) => (
                <Collapse in={open}>
                  <Grid style={{ borderLeft: "#000000", border: "1px" }}>
                    <NavLink
                      key={indexSub}
                      onClick={() => handleClickSubMenu(itemSub.link)}
                      style={{
                        backgroundColor: "#000000",
                        color: "#000000",
                        textDecoration: "none",
                      }}
                      to={itemSub.link}
                    >
                      <List component="div" disablePadding>
                        <ListItemButton
                          selected={itemSub.link === location.pathname}
                          sx={{ pl: 4 }}
                        >
                          <ListItemIcon>{itemSub.icon}</ListItemIcon>
                          <ListItemText>{itemSub.label}</ListItemText>
                        </ListItemButton>
                      </List>
                    </NavLink>
                  </Grid>
                </Collapse>
              ))}
            </NavLink>
          ))}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
}
