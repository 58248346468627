import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { AggregationQr } from "@domain/model/aggregationQr";

interface TableQrListProps {
  items: AggregationQr[] | undefined | any;
  onChangePaginate?: (event: React.ChangeEvent<unknown>, page: number) => void;
  rowsPerPage?: number;
  paginateCount?: number;
}

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [createColumn(1, "Qr UUID")];

export function TableQrList(props: TableQrListProps) {
  return (
    <div>
      {props.items.length === 0 ? (
        ""
      ) : (
        <TableContainer variant="outlined" component={Paper}>
          <Table sx={{ width: "100%" }} aria-label="simple table">
            <TableHead>
              {/* <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.name}</TableCell>
                ))}
              </TableRow> */}
            </TableHead>
            <TableBody>
              {props.items?.map((item: any, index: any) => (
                <TableRow key={index}>
                  <TableCell align="left">{item.qrUUID}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
