//material
import { AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { LoadingButton } from "@mui/lab";
import Accordion from "@mui/material/Accordion";

//icon
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//delivery
import { MsTextField } from "@delivery/components/TextField/TextField";
import { MsLoading } from "@delivery/components/Loading/Loading";
import { ListQr } from "@/delivery/pages/Admin/Aggregate/ListQr";
import { ListAggregation } from "@delivery/pages/Admin/Aggregate/ListAggregation";
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";

//hook
import useAggregatePack from "@/store/hook/aggregatePack";
import useAggregate from "@/store/hook/aggregateQr";
import handleLabelAggregate from "@/infrastructure/utils/getGenerateLabel";
//hook
import printBarcodeHook from "@/store/hook/printBarcodeAggregate";
import React, { useEffect, useState } from "react";
import { MsTemplatePrint } from "@/delivery/components/TemplatePrint/TemplatePrint";
import { useReactToPrint } from "react-to-print";
import { MsButton } from "@/delivery/components/Button/Button";
import { MsAutocomplete } from "@/delivery/components/Autocomplete/autocomplete";

export function AggregatePack() {
  const {
    aggregationList,
    handleAddUuid,
    setQrList,
    qrList,
    setUuid,
    uuid,
    uuidCheckLoading,
    handleSubmit,
    loadingSubmit,
    validation,
    label,
    setLabel,
    alert,
    handleOnClose,
    handleRemove,
    aggProduct,
    handleRemoveList,
    dataPrint,
    products,
    product,
    handleSelectedProductOnPack,
  } = useAggregatePack();

  // const { handleAddUuid } = useAggregate();
  const [labelPage, setLabelPage] = useState("");
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const [disabled, setDisabled] = useState(true);

  const componentRef = React.useRef<HTMLDivElement>(null);
  const _dataPrintBarcode = React.useContext(printBarcodeHook.Context);

  const AggregationToPrint = React.forwardRef((props: any, ref: any) => {
    return (
      <div ref={ref} style={{ border: 1, borderRadius: "7px" }}>
        <MsTemplatePrint data={_dataPrintBarcode?.dataPrint} />
      </div>
    );
  });

  /* -------------------------------- accordion ------------------------------- */
  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  /* ------------------------------ action print ------------------------------ */

  const printLabelBarcode = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  //handle action print
  useEffect(() => {
    if (_dataPrintBarcode?.dataPrint) {
      printLabelBarcode();
    }
  }, [_dataPrintBarcode?.dataPrint]);

  //handle submit data to print
  useEffect(() => {
    if (dataPrint) {
      _dataPrintBarcode?.handleGetDataPrint(dataPrint);
    }
  }, [dataPrint]);

  /* ---------------------------- end action print ---------------------------- */

  /* ----------------------------- validation form ---------------------------- */
  // useEffect(() => {
  //   if (product) {
  //     setDisabled(false);
  //   }
  // }, [product]);

  useEffect(() => {
    const label = handleLabelAggregate();
    setLabel(label);
    setLabelPage(label);
  }, []);

  //check duplicate product
  const productList = new Set();
  const duplicateProducts: any = [];

  for (const obj of qrList) {
    if (productList.has(obj.serialCode)) {
      if (!duplicateProducts.includes(obj.serialCode)) {
        duplicateProducts.push(obj.serialCode);
      }
    } else {
      productList.add(obj.serialCode);
    }
  }

  //check duplicate color product
  // qrList.forEach((item: any) => {
  //   if (duplicateProducts.includes(item.serialCode)) {
  //     item.isDuplicate = true;
  //     setQrList({ ...item, isDuplicate: true });
  //     // setQrList()
  //   }
  // });

  /* ----------------------- MAKE RED WHEN SAME PRODUCT ----------------------- */
  // useEffect(() => {
  //   console.log(qrList, "list");
  //   console.log(duplicateProducts, "list");

  //   if (duplicateProducts.length > 0) {
  //     const updatedQrList = qrList.map((item: any) => {
  //       if (duplicateProducts.includes(item.serialCode)) {
  //         return { ...item, isDuplicate: true };
  //       }
  //       return item;
  //     });

  //     setQrList(updatedQrList);
  //   }
  // }, [qrList]);

  return (
    <Grid>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnClose}
      />
      <Typography variant="h5">Aggregate</Typography>
      <Grid item container sx={{ py: 3 }}>
        {/* left side */}
        <Grid sx={{ px: 2 }} item xs={12} md={6} lg={6}>
          <p>Add Aggregate</p>
          {/* product */}
          {/* <MsSelect
            value={productSelected}
            size="small"
            items={products}
            label="Product"
            onChange={handleChangeProductSelected}
          /> */}

          {/* <MsAutocomplete
            label="Product"
            items={products}
            onChange={handleSelectedProduct}
            // onChange={handleChangeProductSelected}
            size="small"
          /> */}

          {/* label */}
          <TextField
            sx={{ mt: 1 }}
            disabled={true}
            margin="none"
            id="name"
            label="Label"
            fullWidth
            variant="outlined"
            size="small"
            value={labelPage}
            // onChange={(e) => setLabel(e.target.value)}
          />
          {/* button add */}
          <Grid sx={{ my: 2 }}>
            <LoadingButton
              loading={loadingSubmit}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              size="small"
              disabled={validation}>
              <AddIcon fontSize="small" /> Submit Aggregation
            </LoadingButton>
          </Grid>
        </Grid>

        {/* right side */}
        <Grid item sx={{ px: 2, mt: 1 }} xs={12} md={6} lg={6}>
          <p>Scan Qr</p>
          {/* <Grid sx={{ mb: 1 }}>
            <MsAutocomplete
              label="Product"
              items={products}
              onChange={handleSelectedProductOnPack}
              // onChange={handleChangeProductSelected}
              size="small"
            />
          </Grid> */}
          <MsTextField
            autofocus={true}
            label="UUID"
            helperText="put cursor here to scan"
            value={uuid}
            onChange={(e) => setUuid(e.target.value)}
            onKeyEnter={handleAddUuid}
          />
          <Grid sx={{ height: "10px" }}>
            <MsLoading variant="linear" show={uuidCheckLoading} />
          </Grid>
          {/* {qrList.length !== 0 ? (
            <>
              {aggProduct.product_id === 0 ? (
                <Grid sx={{ height: "10px" }}>
                  <b>PRODUK TIDAK TERSEDIA SILAHKAN PILIH PRODUK</b>
                </Grid>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )} */}

          {duplicateProducts.length > 0 ? (
            <>
              <b>Duplicate Product:</b>
              {duplicateProducts.map((item: any, index: number) => (
                <p>
                  Serial ID: <b>{item}</b>
                </p>
              ))}
            </>
          ) : (
            ""
          )}

          <Grid item sx={{ py: 2 }}>
            {/* //table push array qr */}
            {/* <TableListAggregation items={aggregationList} /> */}
            {/* <ListQr
              onClickRemove={(index: any) => handleRemove(index)}
              product={aggProduct}
              items={qrList}
            />
            <ListAggregation
              onClickRemoveList={(index: any) => handleRemoveList(index)}
              product={aggProduct}
              items={aggregationList}
            /> */}
          </Grid>
          <br />
          {qrList.length > 0 || aggregationList.length > 0 ? (
            <Grid>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {aggProduct?.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Count: {qrList.length}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ListQr
                    onClickRemove={(index: any) => handleRemove(index)}
                    product={aggProduct}
                    items={qrList}
                  />
                  <ListAggregation
                    onClickRemoveList={(index: any) => handleRemoveList(index)}
                    product={aggProduct}
                    items={aggregationList}
                  />
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        {/* TEMPLATE PRINT */}
        <div style={{ display: "none" }}>
          <AggregationToPrint ref={componentRef} />
        </div>
      </Grid>
    </Grid>
  );
}
