import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { AggregationQr } from "@domain/model/aggregationQr";

interface TableAggregationProps {
  items: AggregationQr[];
  onPageChange?: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
  onRowsPerPageChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  page?: number;
  rowsPerPage?: number;
}

function createColumn(id: string | number, name: string) {
  return { id, name };
}
const columns = [createColumn(1, "QR UUID")];

export function TableQrAggregationList(props: TableAggregationProps) {
  return (
    <div>
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item: any, index) => (
              <TableRow key={index}>
                <TableCell align="left">{item.qrUUID}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
