//react
import { useEffect, useState } from "react";

//repository
import { getProduct } from "@repository/api/product";
import { fetchQrAssociation } from "@repository/api/qrAggregation";
import { qrCheck } from "@repository/api/qrCheck";

//utility
import getUUID from "@/infrastructure/utils/get_uuid";

//interface
import { Alert } from "@domain/interfaces/alert";

//hook
import useSetting from "@/store/hook/setting";

//constanta
import {
  AGG_BALI,
  AGG_MALANG,
  QR_BALI,
  QR_MALANG,
} from "@/domain/constanta/setting";
import checkQr from "@/infrastructure/utils/check_qr";
import useAggregate from "./aggregateQr";

const useAggregateQrPack = () => {
  // const { productSelected } = useAggregate();
  const [aggregationList, setAggregationList] = useState<[] | any | undefined>(
    []
  );

  const [qrList, setQrList] = useState<any>([]);
  const [productSelected, setProductSelected] = useState<string>("");
  const [uuid, setUuid] = useState("");

  //alert error
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });

  //main form
  const [submitedUUID, setSubmitedUUID] = useState<any>([]);
  const [uuidCheckLoading, setUuidCheckLoading] = useState(false);

  const { cookies } = useSetting();
  let isCheckOnScan = cookies.check_qr === "true";

  //product name on aggregate
  const [aggProduct, setAggProduct] = useState({ product_id: 0, name: "" });

  //   --- END HOOK ---

  //handle product selected
  const handleSelectedProduct = (event: any, value: any) => {
    setProductSelected(value.ID);
  };

  //handle scan
  const handleAddUuid = (e: any) => {
    if (e.key === "Enter") {
      //get uuid from link QR
      let qrCheckResult = checkQr(uuid);

      setUuidCheckLoading(true);

      //if check qr on
      if (isCheckOnScan === true) {
        //CHECK AGGREGATION BALI
        if (qrCheckResult === AGG_BALI) {
          fetchQrAssociation(uuid)
            .then((response) => {
              if (qrList.length === 0) {
                //chack double if double insert push data
                for (let i = 0; i < aggregationList.length; i++) {
                  if (
                    response.aggregation.UUID ===
                    aggregationList[i].aggregation.UUID
                  ) {
                    setAlert({
                      show: true,
                      message: "QR has been inserted",
                      severity: "error",
                    });
                    setUuidCheckLoading(false);
                    return;
                  }

                  //check same level
                  if (
                    response.aggregation.level !==
                    aggregationList[i].aggregation.level
                  ) {
                    setAlert({
                      show: true,
                      message: "Can't insert same level",
                      severity: "error",
                    });
                    setUuidCheckLoading(false);
                    return;
                  }
                }

                //check product name by id
                if (aggProduct.name === "") {
                  let data = {
                    id: response.productID,
                  };
                  getProduct(data)
                    .then((res) => {
                      setAggProduct({
                        name: res.name,
                        product_id: res.ID,
                      });
                    })
                    .catch((error) => {
                      setAlert({
                        show: true,
                        message: error.response.data.message,
                        severity: "error",
                      });
                    });
                }

                //check if same product
                if (aggProduct.name !== "") {
                  if (aggProduct.product_id !== response.productID) {
                    setAlert({
                      show: true,
                      message: "Product may not be the same",
                      severity: "error",
                    });
                    setUuidCheckLoading(false);
                    return;
                  }
                }

                // PUSH list aggregation
                setAggregationList((prevData: any) => [...prevData, response]);
                setSubmitedUUID((prevData: any) => [...prevData, uuid]);
                setUuidCheckLoading(false);
                return;
              }
              //error
              setAlert({
                show: true,
                message:
                  "Aggregated product and single product can't be on one aggreagtion",
                severity: "error",
              });
              setUuidCheckLoading(false);
            })
            .catch((error) => {
              if (error.response.status !== 200) {
                setAlert({
                  show: true,
                  message: error.response.data.Detail,
                  severity: "error",
                });
                setUuidCheckLoading(false);
                return;
              }
            });
          setUuid("");
        }

        //CHECK QR BALI
        if (qrCheckResult === QR_BALI) {
          let newUUID = getUUID(uuid);
          qrCheck(newUUID)
            .then((result) => {
              let uniqID = result.uniqId;
              let serialID = result.serialCode;
              //check qr active

              /* ---------------------- ACTIVATE CHECK ACTIVE ON SCAN --------------------- */

              if (result.status !== 10) {
                setAlert({
                  show: true,
                  message: "please active your QR before agregate",
                  severity: "error",
                });
                setUuidCheckLoading(false);
                return;
              }

              /* ----------------------------------- END ---------------------------------- */

              if (aggregationList.length === 0) {
                //check list qr and aggregation
                //PUSH array qr list
                // for (let i = 0; i < submitedUUID.length; i++) {
                //   if (submitedUUID[i] === newUUID) {
                //     setAlert({
                //       show: true,
                //       message: "QR has been inserted",
                //       severity: "error",
                //     });
                //     setUuidCheckLoading(false);
                //     return;
                //   }
                // }

                for (let i = 0; i < qrList.length; i++) {
                  if (qrList[i].UUID === newUUID) {
                    setAlert({
                      show: true,
                      message: "QR has been inserted",
                      severity: "error",
                    });
                    setUuidCheckLoading(false);
                    return;
                  }
                }

                //check product name by id
                if (aggProduct.name === "") {
                  //check product with selected product
                  if (productSelected) {
                    if (productSelected !== result.productId) {
                      setAlert({
                        show: true,
                        message: "product different from selected",
                        severity: "error",
                      });
                      setUuidCheckLoading(false);
                      return;
                    }
                  }

                  let data = {
                    id: result.productId,
                  };
                  getProduct(data)
                    .then((res) => {
                      setAggProduct({
                        name: res.name,
                        product_id: res.ID,
                      });
                    })
                    .catch((error) => {
                      setAlert({
                        show: true,
                        message: error.response.data.message,
                        severity: "error",
                      });
                    });
                }

                //check if same product
                if (aggProduct.name !== "") {
                  if (aggProduct.product_id !== result.productId) {
                    setAlert({
                      show: true,
                      message: "Product is different",
                      severity: "error",
                    });
                    setUuidCheckLoading(false);
                    return;
                  }
                }

                setQrList((prevData: any) => [
                  ...prevData,
                  { isDuplicate: false, UUID: newUUID, serialCode: serialID },
                ]);
                // setQrList((prevData: any) => [...prevData, serialID]);
                // setSubmitedUUID((prevData: any) => [...prevData, newUUID]);
                setUuidCheckLoading(false);
                return;
              }
              //error
              setAlert({
                show: true,
                message:
                  "Aggregated product and single product can't be on one aggreagtion",
                severity: "error",
              });

              setUuidCheckLoading(false);
            })
            .catch((error) => {
              if (error.response.status !== 200) {
                setAlert({
                  show: true,
                  message: error.response.data.Detail,
                  severity: "error",
                });
                setUuidCheckLoading(false);
                return;
              }
            });
          setUuid("");
        }

        if (qrCheckResult === AGG_MALANG) {
          //do something
        }

        if (qrCheckResult === QR_MALANG) {
          //do something
        }

        if (
          qrCheckResult !== AGG_BALI &&
          qrCheckResult !== QR_BALI &&
          qrCheckResult !== AGG_BALI &&
          qrCheckResult !== AGG_MALANG &&
          qrCheckResult !== QR_MALANG
        ) {
          setAlert({
            show: true,
            message: "Qr Code is NOT Valid",
            severity: "error",
          });
          setUuidCheckLoading(false);
          setUuid("");
          return;
        }
      }

      //if check qr off
      if (isCheckOnScan === false) {
        let newUUID = getUUID(uuid);

        for (let i = 0; i < qrList.length; i++) {
          if (qrList[i] === qrCheckResult) {
            setAlert({
              show: true,
              message: "QR has been inserted",
              severity: "error",
            });
            setUuidCheckLoading(false);
            return;
          }
        }

        setQrList((prevData: any) => [...prevData, newUUID]);
        setSubmitedUUID((prevData: any) => [...prevData, newUUID]);
        setUuidCheckLoading(false);
        setUuid("");
        return;
      }
    }
  };
  //handle remove list
  const handleRemove = (index: number) => {
    qrList.splice(index, 1);
    setQrList([
      ...qrList.slice(0, index),
      ...qrList.slice(index, qrList.length),
    ]);

    submitedUUID.splice(index, 1);
    setSubmitedUUID([
      ...submitedUUID.slice(0, index),
      ...submitedUUID.slice(index, submitedUUID.length),
    ]);
  };

  //handle remove list aggregation
  const handleRemoveList = (index: number) => {
    aggregationList.splice(index, 1);
    setAggregationList([
      ...aggregationList.slice(0, index),
      ...aggregationList.slice(index, aggregationList.length),
    ]);

    submitedUUID.splice(index, 1);
    setSubmitedUUID([
      ...submitedUUID.slice(0, index),
      ...submitedUUID.slice(index, submitedUUID.length),
    ]);
  };

  return {
    handleSelectedProduct,
    aggProduct,
    setAggProduct,
    setUuid,
    uuid,
    setSubmitedUUID,
    submitedUUID,
    setUuidCheckLoading,
    uuidCheckLoading,
    alert,
    handleAddUuid,
    handleRemove,
    handleRemoveList,
    setAggregationList,
    setQrList,
    qrList,
    setAlert,
    aggregationList,
  };
};

export default useAggregateQrPack;
