//material
import { Grid, Typography, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

//hook
import useAssociation from "@/store/hook/associationCheck";

//delivery
import { MsTextField } from "@delivery/components/TextField/TextField";
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";
import { CardAggregation } from "@delivery/pages/Admin/QrAggregation/CardAggregation";
import { ViewAssociationJson } from "@delivery/pages/Admin/Association/ViewAssociationJson";

export function Association() {
  const {
    alert,
    handleOnClose,
    uuid,
    setUUID,
    handleScan,
    loadingShowData,
    showData,
    aggregation,
    subAggregation,
  } = useAssociation();

  return (
    <Grid sx={{ width: "100%" }} item container>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={1000}
        onClose={handleOnClose}
      />
      <Grid sx={{ py: 1 }}>
        <Typography variant="h5">Check Association Aggregation</Typography>
      </Grid>
      <Grid item container sx={{ py: 2 }}>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <MsTextField
            autofocus
            helperText="put cursor here to scan"
            value={uuid}
            label="Qr Code"
            onChange={(e) => setUUID(e.target.value)}
            onKeyEnter={(ev) => {
              if (ev.key === "Enter") {
                handleScan();
                ev.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={6} md={6} sx={{ px: 1 }}>
          <Box display={"flex"} justifyContent="start" alignSelf={"center"}>
            <LoadingButton
              loading={loadingShowData}
              loadingPosition="center"
              variant="contained"
              disableElevation
              onClick={handleScan}
            >
              Search
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
      {showData ? (
        <Grid xs={12} md={12} lg={12} item>
          {/* card top */}
          <Grid sx={{ width: "100%" }}>
            <Grid item container sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={6} sx={{ px: 1 }}>
                <Grid item container>
                  <Grid item md={6}>
                    <Typography variant="h6">Aggregation</Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Box
                      display={"flex"}
                      justifyContent="end"
                      alignSelf={"center"}
                    ></Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container sx={{ py: 1 }}>
              <Grid item xs={12} sm={12} md={6} sx={{ px: 1 }}>
                <CardAggregation items={aggregation} />
              </Grid>
            </Grid>
            <Grid>
              <ViewAssociationJson items={subAggregation} />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
}
