//react
import { useState } from "react";

//repository
import { detach } from "@repository/api/aggregation";

//utility
import getUUID from "@/infrastructure/utils/get_uuid";

//interface
import { Alert } from "@domain/interfaces/alert";

const useDetach = () => {
  //alert error
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });
  const [uuid, setUUID] = useState("");
  const [loadingShowData, setLoadingShowData] = useState(false);

  const handleScan = () => {
    let newUUID = getUUID(uuid);
    let data = {
      uuid: newUUID,
    };
    setLoadingShowData(true);
    detach(data)
      .then((result) => {
        setAlert({
          message: "Detach Successfuly",
          severity: "success",
          show: true,
        });
        setUUID("");
        setLoadingShowData(false);
      })
      .catch((error) => {
        setUUID("");
        setLoadingShowData(false);
        setAlert({
          message: error.response.data.Detail,
          severity: "error",
          show: true,
        });
        setLoadingShowData(false);
      });
  };

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  return { uuid, setUUID, handleScan, loadingShowData, alert, handleOnClose };
};

export default useDetach;
