import { Product } from "@/domain/model/product";
import { Autocomplete, Box, FormControl, TextField } from "@mui/material";

interface MsAutocompleteProps {
  label?: string;
  value?: number | string;
  onChange:
    | ((event: React.SyntheticEvent<Element, Event>, value: any) => void)
    | undefined;
  items: Product[] | [] | any;
  size?: "small" | "medium" | undefined;
  disabled?: boolean;
  itemsAggregation?: [] | undefined;
}

export function MsAutocomplete(props: MsAutocompleteProps) {
  const { items, onChange, size, disabled, label } = props;
  return (
    <>
      <Box sx={{ minWidth: 120, width: "100%" }}>
        <FormControl size={props.size} fullWidth>
          <Autocomplete
            disableClearable
            fullWidth
            disabled={disabled}
            size={size}
            onChange={onChange}
            getOptionLabel={(option: any) => option.name}
            groupBy={(option: any) => option.categoryName}
            disablePortal
            id="combo-box-demo"
            options={items}
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label={label} />}
            renderOption={(props, option) => (
              <Box width={"100%"} component="li" {...props} key={option.id}>
                {option.name}
              </Box>
            )}
          />
        </FormControl>
      </Box>
    </>
  );
}
