//material
import { Grid, Typography, Table, TableRow, TableCell } from "@mui/material";

//delivery
import { MsBarcode } from "@delivery/components/Barcode/Barcode";

//model
import { Aggregation } from "@domain/model/aggregation";
import { useContext, useEffect, useState } from "react";
import printBarcodeAggregate from "@/store/hook/printBarcodeAggregate";
import { fetchQrAssociation } from "@/repository/api/qrAggregation";
import { getProduct } from "@/repository/api/product";
import { Product } from "@/domain/model/product";
import { MSLabelList } from "../LabelList/LabelList";
import { CheckQrModel } from "@/domain/model/checkQr";
import {
  changeMonthYear,
  changeTimeFormat,
} from "@/infrastructure/utils/convert_date_time";

//interface
import DataPrintAgg from "@domain/interfaces/dataPrintAgg";
import DataBatch from "@/domain/interfaces/dataBatch";

interface MsTemplatePrintProps {
  data?: DataPrintAgg | undefined;
}

export function MsTemplatePrint(props: MsTemplatePrintProps) {
  const dataPrint = useContext(printBarcodeAggregate.Context);
  const { data } = props;

  const _dataPrint = dataPrint?.dataPrint;

  return (
    // <Grid width={"400px"} sx={{ paddingTop: "30px" }}>
    //   <Grid
    //     sx={{ pt: 2, pl: 3, my: 2, display: "flex", justifyContent: "center" }}
    //     item>
    //     <Typography variant="subtitle1">
    //       ID: {data?.aggregation.UUID}
    //     </Typography>
    //   </Grid>
    //   <Grid container item>
    //     <Grid sx={{ pl: 3, my: 1 }} item md={8} xs={8} lg={8}>
    //       {/* date */}
    //       <MSLabelList
    //         title="Tanggal Packing"
    //         subTitle={data?.aggregation.getCreatedAtTimeFormat}
    //       />

    //       {/* expired */}
    //       <MSLabelList
    //         title="Kedaluwarsa"
    //         subTitle={`${changeTimeFormat(data?.qr.expiredAt)}`}
    //       />

    //       {/* quantity */}
    //       <MSLabelList title="Jumlah" subTitle={`${data?.aggregation.qty}`} />
    //     </Grid>
    //     <Grid
    //       sx={{
    //         px: 0,
    //         my: 1,
    //         justifyContent: "center",
    //         alignContent: "center",
    //         display: "flex",
    //       }}
    //       item
    //       md={4}
    //       xs={4}
    //       lg={4}>
    //       <div>
    //         <MsBarcode uuid={data?.aggregation.UUID} size={80} />
    //         <Typography
    //           style={{ textTransform: "uppercase" }}
    //           sx={{ mb: 1 }}
    //           variant="body2">
    //           {data?.aggregation.label}
    //         </Typography>

    //         <div style={{ marginTop: "10px" }}>
    //           {/* label */}
    //           <MSLabelList title="Produk" subTitle={data?.product.name} />

    //           {/* SKU */}
    //           <MSLabelList title="SKU" subTitle={data?.product.getSKU} />
    //         </div>
    //       </div>
    //     </Grid>
    //   </Grid>
    // </Grid>

    <div
      style={{
        marginRight: "2px",
        marginLeft: "2px",
      }}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableRow sx={{ borderBottom: "2px solid black" }}>
          <TableCell
            style={{
              textTransform: "uppercase",
              borderRight: "2px solid black",
            }}
            align="left">
            Agregasi
          </TableCell>
          <TableCell align="left">No. Doc: UAI/L/QC/003.00</TableCell>
        </TableRow>
      </Table>
      <Table>
        <TableCell align="center">
          <TableRow>ID: {data?.aggregation.UUID}</TableRow>
        </TableCell>
      </Table>
      <Table>
        <TableCell align="left">
          <TableRow>Berat:</TableRow>
        </TableCell>
        <TableCell align="left">
          <TableRow>Tanda Tangan:</TableRow>
        </TableCell>
      </Table>

      <table
        style={{
          marginRight: "10px",
          marginLeft: "10px",
          border: "1px solid black",
          borderCollapse: "collapse",
          width: "100%",
        }}>
        <tr>
          <td>
            <b>Nama Produk</b>
          </td>
          <td>{data?.product.name}</td>
        </tr>
        <tr
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}>
          <td>
            <b>SKU</b>
          </td>
          <td>{data?.product.getSKU}</td>
        </tr>
        <tr
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}>
          <td>
            <b>Tanggal Packing</b>
          </td>
          <td>{data?.aggregation.getCreatedAtTimeFormat}</td>
        </tr>
        {/* <tr
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}>
          <td>
            <b>Kedaluwarsa</b>
          </td>
          <td>{`${changeTimeFormat(data?.qr.expiredAt)}`}</td>
        </tr> */}
        <tr
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
          }}>
          <td>
            <b>Jumlah</b>
          </td>
          <td>{`${data?.aggregation.qty}`}</td>
        </tr>
      </table>

      <br />
      <div style={{ display: "flex" }}>
        <div>
          <table
            style={{
              marginRight: "10px",
              marginLeft: "10px",
              border: "1px solid black",
              borderCollapse: "collapse",
            }}>
            <tr
              style={{
                border: "1px solid black",
                borderCollapse: "collapse",
                width: "100%",
              }}>
              <th
                style={{
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}>
                Batch No
              </th>
              <th
                style={{
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}>
                Exp
              </th>
              <th
                style={{
                  borderRight: "1px solid black",
                  textAlign: "center",
                }}>
                Qty
              </th>
            </tr>
            {data?.dataBatch.map((item: DataBatch, index: number) => (
              <tr
                style={{
                  border: "1px solid black",
                  borderCollapse: "collapse",
                  width: "100%",
                }}>
                <td
                  style={{
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}>
                  {item.productionCode ? item.productionCode : "-"}
                </td>
                <td
                  style={{
                    borderRight: "1px solid black",
                    textAlign: "center",
                  }}>
                  {changeMonthYear(item.expiredDate)}
                </td>
                <td
                  style={{
                    textAlign: "center",
                  }}>
                  {item.qty}
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div
          style={{
            marginLeft: "10px",
          }}>
          <MsBarcode uuid={data?.aggregation.UUID} size={80} />
          <Typography
            style={{ textTransform: "uppercase" }}
            sx={{ mb: 1 }}
            variant="body2">
            {data?.aggregation.label}
          </Typography>
        </div>
      </div>
    </div>
  );
}
