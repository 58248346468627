//change time format
import Moment from "moment";

export const changeTimeFormat = (time: any) => {
  // let newTime = new Date(time).toString();
  // return newTime.substring(0, 15);

  return Moment(time).format("DD MMM YYYY");
};

export const changeMonthYear = (time: any) => {
  // let newTime = new Date(time).toString();
  // return newTime.substring(0, 15);

  return Moment(time).format("MMM YYYY");
};
