import { createContext, useState } from "react";

import { Aggregation } from "@/domain/model/aggregation";
import { CheckQrModel } from "@/domain/model/checkQr";
import { Product } from "@/domain/model/product";

import { getAggregation } from "@/repository/api/aggregation";
import { getProduct } from "@/repository/api/product";
import { fetchQrAssociation } from "@/repository/api/qrAggregation";
import { qrCheck } from "@/repository/api/qrCheck";

//interface
import DataPrintAgg from "@domain/interfaces/dataPrintAgg";

interface PrintBarcodeProps {
  handleShowPrintLabelAgg: (_data: any) => void;
  handleGetDataPrint: (data: any) => void;
  dataPrint: any;
}

function printBarcodeHook() {
  const Context = createContext<PrintBarcodeProps | null>(null);

  const Provider = (props: any) => {
    const [dataPrint, setDataPrint] = useState<DataPrintAgg>();

    /* -------------------------------- END HOOK -------------------------------- */

    const handleGetDataPrint = (dataAggregation: Aggregation) => {
      let productParam = {
        id: dataAggregation.productId,
      };

      let aggParam = {
        uuid: dataAggregation.UUID,
      };

      //show product
      getProduct(productParam)
        .then((resultProduct) => {
          //show qr to get qr_uuid
          getAggregation(aggParam)
            .then((result) => {
              //check qr to get expired
              qrCheck(result.aggregationQr[0].qrUUID)
                .then((checkResult) => {
                  let dataPrintProductAgg: DataPrintAgg = {
                    aggregation: dataAggregation,
                    product: resultProduct,
                    qr: checkResult,
                    dataBatch: result.getDataBatch,
                  };
                  setDataPrint(dataPrintProductAgg);
                })
                .catch((err) => {});
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    };

    /* --------------- handle show display print aggregation label -------------- */
    const handleShowPrintLabelAgg = (_data: any) => {};

    const contextState: PrintBarcodeProps = {
      handleShowPrintLabelAgg,
      dataPrint,
      handleGetDataPrint,
    };

    return (
      <Context.Provider value={contextState}>{props.children}</Context.Provider>
    );
  };

  return {
    Context,
    Provider,
  };
}

export default printBarcodeHook();
