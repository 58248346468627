import { BaseModel } from "./base_model";

export class Product extends BaseModel {
  name: string;
  description?: string;
  categoryName: string;
  imageUrl?: string;
  sku: string;
  slug: string;
  tag: string;
  type: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    name: string,
    description: string,
    categoryName: string,
    imageUrl: string,
    sku: string,
    slug: string,
    tag: string,
    type: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.name = name;
    this.description = description;
    this.categoryName = categoryName;
    this.imageUrl = imageUrl;
    this.sku = sku;
    this.slug = slug;
    this.tag = tag;
    this.type = type;
  }

  get getSKU(): string {
    if (this.sku) {
      return this.sku;
    }
    return "-";
  }

  public static fromJsonList(data: any): Product[] | any {
    let productList: Product[] = [];
    for (let i = 0; i < data.length; i++) {
      productList.push(Product.fromJson(data[i]));
    }
    return productList;
  }

  public static fromJson(data: any): Product | any {
    try {
      if (data == null || data === undefined) {
        return undefined;
      }
      return new Product(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.name,
        data.description,
        data.category_name,
        data.image_url,
        data.sku,
        data.slug,
        data.tag,
        data.type
      );
    } catch {
      return null;
    }
  }
}
