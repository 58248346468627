//react
import * as React from "react";
import { useNavigate } from "react-router-dom";

//material
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Chip,
  TablePagination,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

//repository
import { fetchQrAssociation } from "@repository/api/qrAggregation";
import { getWithAssociation } from "@repository/api/aggregation";
import { getProduct } from "@/repository/api/product";

//delivery
import { DialogDetailAggregation } from "@/delivery/pages/Admin/AggregationList/DialogDetailAggregation";
import { MsLoading } from "@delivery/components/Loading/Loading";
import { DialogHistoryAggregation } from "@/delivery/pages/Admin/AggregationList/DialogHistoryAggregation";

//model
import { Aggregation } from "@domain/model/aggregation";
import { AggregationQr } from "@domain/model/aggregationQr";
import { AggregationHistory } from "@/domain/model/aggregationHistory";

//infrastructure
import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";
import shortCharacter from "@/infrastructure/utils/short_character";
import { Product } from "@/domain/model/product";
import printBarcodeHook from "@/store/hook/printBarcodeAggregate";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { AggregationToPrint } from "./AggregateToPrint";
import { MsTemplatePrint } from "@/delivery/components/TemplatePrint/TemplatePrint";
import { qrCheck } from "@/repository/api/qrCheck";
import { getUser } from "@/repository/api/user";

interface TableAggregationProps {
  items: Aggregation[];
  onClickDetail?: (event: React.MouseEvent<HTMLElement>) => void;
  rowsPerPage: number;
  loading: boolean;

  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => void;
  onRowsPerPageChange?:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  totalCount: number;
  page: number;
}

function createColumn(id: string | number, name: string) {
  return { id, name };
}

const columns = [
  createColumn(1, "Label"),
  createColumn(2, "Product"),
  createColumn(3, "Level"),
  createColumn(4, "Qty"),
  createColumn(5, "Created"),
  createColumn(6, "Status"),
  createColumn(7, "Action"),
];

export function TableAgregation(props: TableAggregationProps) {
  const navigate = useNavigate();
  const [detailLoading, setDetailLoading] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showHistory, setShowHistory] = React.useState(false);
  const [dataAggregation, setDataAggregation] = React.useState<
    Aggregation | any
  >();
  const [dataAggregationQr, setDataAggregationQr] = React.useState<
    AggregationQr[] | any
  >();
  const [dataHistory, setDataHistory] = React.useState<
    AggregationHistory[] | any
  >();

  const [dataProduct, setDataProduct] = React.useState<Product>();

  const _dataPrintBarcode = React.useContext(printBarcodeHook.Context);

  //get detail
  const aggregationWithGrandAssociation = (params: string) => {
    setDetailLoading(true);
    fetchQrAssociation(params)
      .then((result) => {
        setDataProduct(result.product);
        setDataAggregation(result.aggregation);
        setDataAggregationQr(result.aggregationQr);

        setShowDialog(true);
        setDetailLoading(false);
        //product get
        // let param = {
        //   id: result.productID,
        // };
        // getProduct(param)
        //   .then((resultProduct) => {
        //     setDataProduct(resultProduct);
        //     setDataAggregation(result.aggregation);
        //     setDataAggregationQr(result.aggregationQr);

        //     setShowDialog(true);
        //     setDetailLoading(false);

        //     // getUser(result.aggregation.userId)
        //     //   .then((resultUser) => {
        //     //     console.log(resultUser);

        //     //   })
        //     //   .catch((err) => {});
        //   })
        //   .catch((err) => {});
      })
      .catch((err) => {
        setDetailLoading(false);
      });
  };

  //get history
  const handleGetHistory = (params: string) => {
    setDetailLoading(true);
    getWithAssociation(params)
      .then((result) => {
        setShowHistory(true);
        setDataHistory(result.aggregationHistory);
        setDetailLoading(false);
      })
      .catch((err) => {
        setDetailLoading(false);
      });
  };

  //handle close dialog
  const handleCloseDialog = () => {
    setShowHistory(false);
    setShowDialog(false);
  };

  //print aggregate
  const printAggregate = (item: any) => {
    console.log(item, "dat");
    // navigate("/aggregate-print", {
    //   state: {
    //     uuid: item.UUID,
    //     aggregationQr: item,
    //   },
    // });
  };

  /* ---------------------------------- print --------------------------------- */
  const printLabelBarcode = useReactToPrint({
    content: () => componentRef.current,
  });

  React.useEffect(() => {
    if (_dataPrintBarcode?.dataPrint) {
      printLabelBarcode();
    }
  }, [_dataPrintBarcode?.dataPrint]);

  const componentRef = React.useRef<HTMLDivElement>(null);

  const AggregationToPrint = React.forwardRef((props: any, ref: any) => {
    return (
      <div ref={ref} style={{ border: 1, borderRadius: "7px" }}>
        <MsTemplatePrint data={_dataPrintBarcode?.dataPrint} />
      </div>
    );
  });

  /* -------------------------------- end print ------------------------------- */

  return (
    <>
      <MsLoading variant="linear" show={props.loading} />
      <MsLoading variant="linear" show={detailLoading} />
      <TableContainer variant="outlined" component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#eceef0" }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id}>{column.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {props.items.length === 0 ? (
              <TableRow>
                <Stack sx={{ py: 2, textAlign: "center" }} spacing={2}>
                  <p>Data Kosong</p>
                </Stack>
              </TableRow>
            ) : (
              props.items?.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell
                    style={{ textTransform: "uppercase" }}
                    align="left">
                    {item.label}
                  </TableCell>
                  <TableCell align="left">{`${
                    item.product?.name ?? "-"
                  }`}</TableCell>
                  <TableCell align="left">{item.level}</TableCell>
                  <TableCell align="left">{item.qty}</TableCell>
                  <TableCell align="left">
                    {changeTimeFormat(item.createdAt)}
                  </TableCell>
                  <TableCell align="left">
                    {item.qty === 0 ? (
                      <Chip
                        label={"detached"}
                        color={"error"}
                        variant="filled"
                        size="small"
                      />
                    ) : (
                      <Chip
                        label={item.status}
                        color={item.status === "active" ? "success" : "error"}
                        variant="filled"
                        size="small"
                      />
                    )}
                  </TableCell>
                  <TableCell align="left">
                    <LoadingButton
                      sx={{ mr: 1 }}
                      size="small"
                      disableElevation
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        handleGetHistory(item.UUID);
                      }}>
                      History
                    </LoadingButton>
                    <LoadingButton
                      sx={{ mr: 1 }}
                      size="small"
                      disableElevation
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        aggregationWithGrandAssociation(item.UUID);
                      }}>
                      Detail
                    </LoadingButton>
                    <LoadingButton
                      disabled={item.qty === 0 ? true : false}
                      sx={{ mr: 1 }}
                      size="small"
                      disableElevation
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        _dataPrintBarcode?.handleGetDataPrint(item);
                      }}>
                      Print
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid sx={{ display: "flex", justifyContent: "center" }}>
        <Stack sx={{ py: 2 }} spacing={2}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={props.totalCount}
            page={props.page}
            onPageChange={props.onPageChange}
            rowsPerPage={props.rowsPerPage}
            onRowsPerPageChange={props.onRowsPerPageChange}
          />
        </Stack>
      </Grid>

      {/* TEMPLATE PRINT */}
      <div style={{ display: "none" }}>
        <AggregationToPrint ref={componentRef} />
      </div>

      <DialogDetailAggregation
        itemsProduct={dataProduct}
        itemsAggregation={dataAggregation}
        itemsAggregationQr={dataAggregationQr}
        onClickClose={handleCloseDialog}
        show={showDialog}
      />
      <DialogHistoryAggregation
        onClickClose={handleCloseDialog}
        show={showHistory}
        itemsAggregationHistory={dataHistory}
      />
    </>
  );
}
