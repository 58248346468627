//material
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  SelectChangeEvent,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";

//icon
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

//delivery
import { MsAutocomplete } from "@/delivery/components/Autocomplete/autocomplete";
import { MsSelect } from "@delivery/components/Select/Select";
import { MsTextField } from "@delivery/components/TextField/TextField";
import { MsLoading } from "@delivery/components/Loading/Loading";
import { ListQr } from "@/delivery/pages/Admin/Aggregate/ListQr";
import { ListAggregation } from "@delivery/pages/Admin/Aggregate/ListAggregation";
import { MsSnackBar } from "@delivery/components/SnackBar/SnackBar";

//hook
import useAggregate from "@/store/hook/aggregateQr";
import printBarcodeHook from "@/store/hook/printBarcodeAggregate";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { MsTemplatePrint } from "@/delivery/components/TemplatePrint/TemplatePrint";
import { useReactToPrint } from "react-to-print";
import { MsButton } from "@/delivery/components/Button/Button";
import handleLabelAggregate from "@/infrastructure/utils/getGenerateLabel";

export function Aggregate() {
  const {
    aggProduct,
    setAggProduct,
    scanFocus,
    setUuid,
    uuid,
    handleAddUuid,
    uuidCheckLoading,
    qrList,
    handleStartAggregate,
    handleStopAggregate,
    validation,
    label,
    setLabel,
    setQty,
    qty,
    autoScan,
    startAggregate,
    productSelected,
    products,
    handleChangeProductSelected,
    handleOnClose,
    alert,
    aggregationList,
    handleRemove,
    handleRemoveList,
    dataPrint,
    handleSelectedProduct,
  } = useAggregate();

  const [labelPage, setLabelPage] = useState("");
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const componentRef = React.useRef<HTMLDivElement>(null);
  const _dataPrintBarcode = React.useContext(printBarcodeHook.Context);

  const AggregationToPrint = React.forwardRef((props: any, ref: any) => {
    return (
      <div ref={ref} style={{ border: 1, borderRadius: "7px" }}>
        <MsTemplatePrint data={_dataPrintBarcode?.dataPrint} />
      </div>
    );
  });

  /* -------------------------------- accordion ------------------------------- */
  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  /* ------------------------------ action print ------------------------------ */

  const printLabelBarcode = useReactToPrint({
    content: () => componentRef.current,
  });

  //handle action print
  useEffect(() => {
    if (_dataPrintBarcode?.dataPrint) {
      printLabelBarcode();
    }
  }, [_dataPrintBarcode?.dataPrint]);

  //handle submit data to print
  useEffect(() => {
    if (dataPrint) {
      _dataPrintBarcode?.handleGetDataPrint(dataPrint);
    }
  }, [dataPrint]);

  useEffect(() => {
    const label = handleLabelAggregate();
    setLabel(label);
    setLabelPage(label);
  }, []);

  return (
    <Grid>
      <MsSnackBar
        message={alert.message}
        open={alert.show}
        severity={alert.severity}
        duration={3000}
        onClose={handleOnClose}
      />
      <Typography variant="h5">Aggregate Product</Typography>

      <Grid item container sx={{ py: 3 }}>
        {/* left side */}
        <Grid sx={{ px: 2 }} item xs={12} md={6} lg={6}>
          <p>Add Aggregate</p>
          {/* product */}
          <MsAutocomplete
            label="Product"
            items={products}
            onChange={handleChangeProductSelected}
            size="small"
          />
          {/* Quantity */}
          <TextField
            sx={{ display: autoScan === false ? "none" : "" }}
            disabled={startAggregate.dataAgg}
            type={"number"}
            margin="dense"
            label="Quantity per Pack"
            fullWidth
            variant="outlined"
            size="small"
            value={qty}
            onChange={(e: any) => {
              setQty(parseInt(e.target.value));
            }}
          />
          {/* label */}
          <TextField
            inputProps={{ style: { textTransform: "uppercase" } }}
            // disabled={startAggregate.dataAgg}
            disabled={true}
            margin="dense"
            id="name"
            label="Label / Prefix"
            fullWidth
            variant="outlined"
            size="small"
            value={labelPage}
            // onChange={() => setLabel(handleLabelAggregate())}
          />
          {/* checkbox */}
          {/* <Grid sx={{ my: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() =>
                    autoScan === false ? setAutoScan(true) : setAutoScan(false)
                  }
                  defaultChecked={autoScan}
                />
              }
              label="Auto Scan"
            />
            <FormControlLabel
              control={<Checkbox disabled defaultChecked={autoPrint} />}
              label="Auto Print"
            />
          </Grid> */}

          {/* button add */}
          <Grid sx={{ my: 2 }}>
            {/* <Button
              onClick={handleSubmit}
              disabled={autoScan}
              size="small"
              sx={{ ml: 1 }}
              variant="contained"
              color="primary"
            >
              <AddIcon />
              Aggregate
            </Button> */}
            <Button
              onClick={handleStartAggregate}
              disabled={validation}
              size="small"
              sx={{ ml: 1 }}
              variant="contained"
              color="primary">
              <PlayArrowIcon />
              Start Aggregate
            </Button>
            <Button
              onClick={handleStopAggregate}
              disabled={startAggregate.dataQr}
              size="small"
              sx={{ ml: 1 }}
              variant="contained"
              color="secondary">
              <StopCircleIcon />
              Stop Aggregate
            </Button>
          </Grid>
        </Grid>

        {/* right side */}
        <Grid item sx={{ px: 2 }} xs={12} md={6} lg={6}>
          <p>Scan Qr</p>
          <MsTextField
            autofocus={true}
            disabled={autoScan === true ? startAggregate.dataQr : autoScan}
            label="Qr Code"
            helperText="put cursor here to scan"
            value={uuid}
            onChange={(e) => setUuid(e.target.value)}
            onKeyEnter={handleAddUuid}
          />
          <Grid sx={{ height: "10px" }}>
            <MsLoading variant="linear" show={uuidCheckLoading} />
          </Grid>
          <Grid item sx={{ py: 2 }}>
            {/* //table push array qr */}
            {/* <TableListAggregation items={aggregationList} /> */}
          </Grid>
          <br />
          {qrList.length > 0 || aggregationList.length > 0 ? (
            <Grid>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header">
                  <Typography sx={{ width: "33%", flexShrink: 0 }}>
                    {aggProduct?.name}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    Count: {qrList.length}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <ListQr
                      onClickRemove={(index: any) => handleRemove(index)}
                      product={aggProduct}
                      items={qrList}
                    />
                    <ListAggregation
                      onClickRemoveList={(index: any) =>
                        handleRemoveList(index)
                      }
                      product={aggProduct}
                      items={aggregationList}
                    />
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ) : (
            ""
          )}
        </Grid>

        {/* TEMPLATE PRINT */}
        <div style={{ display: "none" }}>
          <AggregationToPrint ref={componentRef} />
        </div>
      </Grid>
    </Grid>
  );
}
