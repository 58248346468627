import { API_MS_SECURE } from "@/domain/constanta/setting";
import { CheckQrModel } from "@/domain/model/checkQr";
import axiosClient from "@/infrastructure/axios/axios";
// import appSignature from "@/infrastructure/utils/signature";

export async function qrCheck(data: any) {
  let res = await axiosClient({
    baseURL: API_MS_SECURE,
    method: "GET",
    url: `/api/v2/qr/${data}`,
  });

  return CheckQrModel.fromJson(res.data);

  // let url = `${API_MS_SECURE}/qr/${data}`;
  // let sign = appSignature(url);
  // return await axios({
  //   method: "GET",
  //   url: url,
  //   headers: {
  //     "Content-Type": "application/json",
  //     Signature: sign,
  //   },
  // });
}
