import axios from "axios";
import { API_URL } from "@domain/constanta/setting";
import { getToken } from "@infrastructure/utils/auth_util";

const axiosClient = axios.create({
  baseURL: `${API_URL}`,
  timeout: 0, // request timeout
  withCredentials: true,
  headers: {
    Authorization: `${getToken()}`,
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let message = "could not decode accessToken: Token is expired";
    let res = error.response;
    if (res.status === 401) {
      alert("Forbidden Access");
    }
    if (res.status === 500 && error.response.data.message === message) {
      alert("do login");
      window.location.href = "/login";
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
