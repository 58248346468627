import { Typography } from "@mui/material";
import ReactJson from "react-json-view";

interface ViewAssociationJsonProps {
  items: [] | any;
}

export function ViewAssociationJson(props: ViewAssociationJsonProps) {
  return (
    <div>
      {props.items ? (
        <div>
          <Typography variant="h6">Sub Aggregation</Typography>
          <ReactJson
            displayObjectSize={false}
            displayDataTypes={false}
            collapsed
            iconStyle="circle"
            src={props.items}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
