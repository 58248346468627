//react
import { useState } from "react";

// repository
import {
  fetchQrAssociation,
  exportQrAggregationCSV,
} from "@repository/api/qrAggregation";

//model
import { AggregationQr } from "@domain/model/aggregationQr";
import { Aggregation } from "@domain/model/aggregation";

//utility
import getUUID from "@/infrastructure/utils/get_uuid";

//interface
import { Alert } from "@domain/interfaces/alert";

const useQrAggregationCheck = () => {
  //alert error
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });
  const [uuid, setUUID] = useState("");
  const [loadingShowData, setLoadingShowData] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [qrAggregations, setQrAggregations] = useState<AggregationQr[]>([]);
  const [aggregation, setAggregation] = useState<Aggregation>();
  const [isShow, setIsShow] = useState(false);

  //handle scan
  const handleScan = () => {
    let newUUID = getUUID(uuid);
    setLoadingShowData(true);
    fetchQrAssociation(newUUID)
      .then((result) => {
        //loading false
        setLoadingShowData(false);

        //result on state
        setQrAggregations(result.aggregationQr);
        setAggregation(result.aggregation);

        //show list
        setIsShow(true);
        setUUID("");
      })
      .catch((error) => {
        setUUID("");
        setLoadingShowData(false);
        setAlert({
          message: error.response.data.Detail,
          severity: "error",
          show: true,
        });
      });
  };

  //handle export aggreagtion
  const handleExportQrAggregation = (UUID: any) => {
    setExportLoading(true);
    exportQrAggregationCSV(UUID)
      .then((result) => {
        window.open(result.data);
        setExportLoading(false);
      })
      .catch((err) => {
        setExportLoading(false);
      });
  };

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  return {
    alert,
    handleOnClose,
    uuid,
    setUUID,
    handleScan,
    loadingShowData,
    isShow,
    exportLoading,
    handleExportQrAggregation,
    aggregation,
    qrAggregations,
  };
};

export default useQrAggregationCheck;
