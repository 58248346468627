import QRCode from "react-qr-code";
import { QR_URL } from "@domain/constanta/setting";

interface MsBarcodeProps {
  uuid: string | undefined;
  size: number;
}

export function MsBarcode(props: MsBarcodeProps) {
  return <QRCode value={`${props.uuid}`} size={props.size} />;
}
