const getUuid = (val: string) => {
  if (val.length <= 36) {
    return val;
  }
  let regexStr = new RegExp("([^\\/]+)?\\w{32}$");
  let uid: RegExpMatchArray | any;
  uid = val.match(regexStr);
  return uid[0];
};

export default getUuid;
