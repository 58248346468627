//material
import { Card, CardContent, Chip, Grid } from "@mui/material";

//delivery
import { MsTextField } from "@delivery/components/TextField/TextField";

//model
import { Aggregation } from "@domain/model/aggregation";
import { Product } from "@/domain/model/product";
import { formatDateTime } from "@/infrastructure/utils/formatDate";

interface CardAggregationProps {
  items: Aggregation | any;
  itemsProduct?: Product | any;
}

export function CardAggregation(props: CardAggregationProps) {
  return (
    <Card variant="outlined" sx={{ my: 1 }}>
      <CardContent>
        <Grid sx={{ mb: 2 }}>
          {props.items.qty > 0 ? (
            <Chip
              label={props.items.status}
              color={"success"}
              variant="filled"
              size="small"
            />
          ) : (
            <Chip
              label={"detached"}
              color={"error"}
              variant="filled"
              size="small"
            />
          )}
        </Grid>
        <MsTextField
          spaceY
          disabled
          variant="standard"
          label="Label"
          value={props.items.label}
        />
        <MsTextField
          spaceY
          variant="standard"
          disabled={true}
          label="UUID"
          value={props.items.UUID}
        />
        <MsTextField
          spaceY
          variant="standard"
          disabled={true}
          label="User"
          value={props.items.userId}
        />
        <MsTextField
          spaceY
          variant="standard"
          disabled={true}
          label="Product"
          value={props.items.product?.name}
        />
        <Grid item container>
          <Grid item md={6} sx={{ pr: 0.5 }}>
            <MsTextField
              spaceY
              variant="standard"
              disabled={true}
              label="Aggregated At"
              value={formatDateTime(props.items.createdAt)}
            />
          </Grid>
          <Grid item md={6} sx={{ pl: 0.5 }}>
            <MsTextField
              spaceY
              variant="standard"
              disabled={true}
              label="Quantity"
              value={props.items.qty}
            />
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item md={6} sx={{ pr: 0.5 }}>
            <MsTextField
              spaceY
              variant="standard"
              disabled={true}
              label="Level"
              value={props.items.level}
            />
          </Grid>
          <Grid item md={6} sx={{ pl: 0.5 }}></Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
