import { changeTimeFormat } from "@/infrastructure/utils/convert_date_time";
import { BaseModel } from "./base_model";

export class CheckQrModel extends BaseModel {
  status: number;
  qrBatchId: number;
  productId: number;
  uniqId: number;
  note: string;
  qrBatch: {};
  serialNumber: number;
  serialCode: string;
  qrProductionHistory: [];
  productionId: number;
  expiredAt: string;

  constructor(
    ID: BigInt,
    UUID: string,
    createdAt: string,
    updateAt: string,
    status: number,
    qrBatchId: number,
    productId: number,
    uniqId: number,
    note: string,
    qrBatch: {},
    serialNumber: number,
    serialCode: string,
    qrProductionHistory: [],
    productionId: number,
    expiredAt: string
  ) {
    super(ID, UUID, createdAt, updateAt);
    this.status = status;
    this.qrBatchId = qrBatchId;
    this.productId = productId;
    this.uniqId = uniqId;
    this.note = note;
    this.qrBatch = qrBatch;
    this.serialNumber = serialNumber;
    this.serialCode = serialCode;
    this.qrProductionHistory = qrProductionHistory;
    this.productionId = productionId;
    this.expiredAt = expiredAt;
  }

  get getExpiredAtDateFormat(): string {
    if (this.expiredAt) {
      return changeTimeFormat(this.createdAt);
    }

    return "-";
  }

  public static fromJson(data: any): CheckQrModel | any {
    try {
      if (data == null || data === undefined) {
        return undefined;
      }
      return new CheckQrModel(
        data.id,
        data.uuid,
        data.created_at,
        data.update_at,
        data.status,
        data.qr_batch_id,
        data.product_id,
        data.uniq_id,
        data.note,
        data.qr_batch,
        data.serial_number,
        data.serial_code,
        data.qr_production_history,
        data.production_id,
        data.expired_at
      );
    } catch {
      return null;
    }
  }

  public static fromJsonList(data: any): CheckQrModel[] | any {
    let list: CheckQrModel[] = [];
    for (let i = 0; i < data.length; i++) {
      list.push(CheckQrModel.fromJson(data[i]));
    }
    return list;
  }
}
