import { useState } from "react";
import { useCookies } from "react-cookie";

const useSetting = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["check_qr"]);
  //dialog setting admin
  const [dialogIsShow, setDialogIsShow] = useState<boolean>(false);
  const [ipServer, setIpServer] = useState("");

  // dialog setting admin
  const [dialogSetting, setDialogSetting] = useState(false);
  const [isCheckOnScan, setCheckOnScan] = useState(true);
  const [settings, setSettings] = useState({ ipServer: "" });

  //handle on save
  const handleOnSaveSetting = () => {};

  //handle on close setting dialog
  const handleIsShowDialog = () => {
    setDialogIsShow(dialogIsShow === true ? false : true);
  };
  const handleShowDialogSettingAdmin = () => {
    setDialogSetting(dialogSetting === true ? false : true);
  };

  //default check on scan
  const handeDefaultCheckOnScan = () => {
    setCookie("check_qr", true);
  };

  //check when scan qr
  const handleCheckOnScan = () => {
    let resultSwitch = cookies.check_qr === "true";
    let check = resultSwitch === true ? false : true;
    setCookie("check_qr", check);
    // setCheckOnScan(cookies.check_qr === "true");
  };

  return {
    handeDefaultCheckOnScan,
    cookies,
    handleCheckOnScan,
    handleShowDialogSettingAdmin,
    dialogSetting,
    setDialogSetting,
    isCheckOnScan,
    setCheckOnScan,
    dialogIsShow,
    ipServer,
    handleOnSaveSetting,
    setIpServer,
    setDialogIsShow,
    handleIsShowDialog,
  };
};

export default useSetting;
