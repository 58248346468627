import { API_AUTH, REALM } from "@/domain/constanta/setting";
import axiosClient from "@infrastructure/axios/axios";

var objectToQueryString = function (obj: any) {
  return Object.keys(obj)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    })
    .join("&");
};

export async function login(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: `${API_AUTH}/realms/${REALM}/protocol/openid-connect/token`,
    data: objectToQueryString(data),
  });

  return res;
}
