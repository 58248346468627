import axiosClient from "@infrastructure/axios/axios";
import { Aggregation } from "@domain/model/aggregation";
import { AggregationHistory } from "@domain/model/aggregationHistory";

//aggreagte
export async function aggregate(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/aggregate",
    data: data,
  });
  return Aggregation.fromJson(res.data);
}

//fetch aggregation paginate
export async function fetchAggregatePaginate(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/paginate",
    data: data,
  });

  let fetchAggregation = {
    aggregation: Aggregation.fromJsonToList(res.data.result),
    pagination: res.data.pagination,
  };

  return fetchAggregation;
}

//get aggregation with grand association
export async function getWithGrandAssociation(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/get/grand-association",
    data: data,
  });

  let getAggregations = {
    aggregation: Aggregation.fromJson(res.data),
    subAggregations: res.data.sub_aggregations,
    aggregationHistory: AggregationHistory.fromJsonToList(
      res.data.aggregation_history
    ),
  };
  return getAggregations;
}

//get aggregation with association
export async function getWithAssociation(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/get/association",
    data: data,
  });

  let getAggregations = {
    aggregation: Aggregation.fromJson(res.data),
    aggregationHistory: AggregationHistory.fromJsonToList(
      res.data.aggregation_history
    ),
    subAggregations: res.data.sub_aggregations,
  };

  return getAggregations;
}

//detach
export async function detach(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/detach",
    data: data,
  });

  return Aggregation.fromJson(res.data);
}

//get aggregation
export async function getAggregation(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/get",
    data: data,
  });

  return Aggregation.fromJson(res.data);
}

// ------NOT CALLED YET BELOW------

//fetch aggregation
export async function fetchAggregation(data: any) {
  let res = await axiosClient({
    method: "POST",
    url: "/aggregation/fetch",
    data: data,
  });

  return Aggregation.fromJsonToList(res.data);
}
