//react
import React from "react";

//material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Button from "@mui/material/Button";

//model
import { AggregationHistory } from "@domain/model/aggregationHistory";
import { AggregationQr } from "@domain/model/aggregationQr";

//delivery
import { TableAggregationHistory } from "@/delivery/pages/Admin/AggregationList/TableAggregationHistory";

interface DialogHistoryAggregationProps {
  title?: string;
  show: boolean;
  itemsAggregationHistory?: AggregationHistory[];
  itemsAggregationQr?: AggregationQr[];
  onClose?: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
  onClickClose?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export function DialogHistoryAggregation(props: DialogHistoryAggregationProps) {
  return (
    <Dialog
      maxWidth={"lg"}
      open={props.show}
      onClose={props.onClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">History Aggregation</DialogTitle>
      <DialogContent dividers={true}>
        <TableAggregationHistory items={props.itemsAggregationHistory} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClickClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
