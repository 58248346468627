//react
import { useEffect, useState } from "react";

//material
import { SelectChangeEvent } from "@mui/material";

//model
import { Product } from "@domain/model/product";

//repository
import { fetchProduct } from "@repository/api/product";

//hook
import useSetting from "@/store/hook/setting";

//constanta
import { aggregate } from "@/repository/api/aggregation";
import useAggregateQrPack from "@store/hook/aggregateQrPack";

const useAggregatePack = () => {
  const {
    aggProduct,
    setAggProduct,
    setUuid,
    uuid,
    setSubmitedUUID,
    submitedUUID,
    setUuidCheckLoading,
    uuidCheckLoading,
    alert,
    handleAddUuid,
    handleRemove,
    handleRemoveList,
    setAggregationList,
    setQrList,
    qrList,
    setAlert,
    aggregationList,
    handleSelectedProduct,
  } = useAggregateQrPack();

  //main form
  const [productSelected, setProductSelected] = useState("");
  const [label, setLabel] = useState<string>("");
  const [products, setProducts] = useState<Product[]>();
  const [product, setProduct] = useState("");
  const [validation, setValidation] = useState(true);
  const [loadingSubmit, setLoadingsubmit] = useState(false);

  const { cookies } = useSetting();
  let isCheckOnScan = cookies.check_qr === "true";
  const [numberLabel, setNumberLabel] = useState(1);
  const [dataPrint, setDataPrint] = useState<any>(null);

  //---- END HOOK-----

  //clear table
  const handleClear = () => {
    setAggregationList([]);
    setQrList([]);
    setSubmitedUUID([]);
  };

  //handle product selected
  const handleChangeProductSelected = (event: SelectChangeEvent) => {
    setProductSelected(event.target.value);
  };

  //handle product selected
  const handleSelectedProductOnPack = (event: any, value: any) => {
    setProduct(value.ID);
  };

  //-------

  useEffect(() => {
    //fetch product
    fetchProduct()
      .then((result) => {
        setProducts(result);
      })
      .catch((error) => {
        setAlert({
          show: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  }, []);

  //watch form validation
  useEffect(() => {
    if (aggregationList.length !== 0) {
      setValidation(false);
      return;
    }

    if (qrList.length !== 0) {
      setValidation(false);
      return;
    }
    setValidation(true);
  }, [aggregationList, qrList]);

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  //watch empty qr list or agg list
  useEffect(() => {
    if (aggregationList.length === 0 && qrList.length === 0) {
      setAggProduct({ name: "", product_id: 0 });
    }
  }, [qrList, aggregationList]);

  //handle duplicate lable submit
  const handleLabelSubmit = (labelName: string) => {
    if (labelName === label) {
      setNumberLabel(numberLabel + 1);
      let number = numberLabel > 1 ? `${numberLabel}` : 1;
      let newLabel = `${label}/${number}`;
      return newLabel;
    }
  };

  //submit list
  const handleSubmit = () => {
    //list UUID
    let UUIDList = [];
    for (let x = 0; qrList.length > x; x++) {
      UUIDList.push(qrList[x].UUID);
    }

    let dataSubmit = {
      product_id: aggProduct.product_id ? aggProduct.product_id : product,
      label: handleLabelSubmit(label),
      list_uuid: UUIDList,
    };

    setLoadingsubmit(true);
    aggregate(dataSubmit)
      .then((result) => {
        setAlert({
          show: true,
          message: "Aggregate Successfully",
          severity: "success",
        });
        setLoadingsubmit(false);
        setDataPrint(result);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        setLoadingsubmit(false);
        setAlert({
          show: true,
          message: error.response.data.Detail,
          severity: "error",
        });
      });
  };

  return {
    handleSelectedProductOnPack,
    product,
    products,
    dataPrint,
    handleRemoveList,
    uuid,
    setUuid,
    handleAddUuid,
    uuidCheckLoading,
    setQrList,
    qrList,
    aggregationList,
    handleClear,
    loadingSubmit,
    handleSubmit,
    label,
    validation,
    setLabel,
    handleOnClose,
    alert,
    handleRemove,
    aggProduct,
  };
};

export default useAggregatePack;
