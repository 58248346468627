//react
import { useEffect, useState } from "react";

//material
import { SelectChangeEvent } from "@mui/material";

// repository
import { fetchAggregatePaginate } from "@repository/api/aggregation";
import { fetchProduct } from "@/repository/api/product";

//utility
import getUUID from "@/infrastructure/utils/get_uuid";

import { Alert } from "@/domain/interfaces/alert";

const useAggregationList = () => {
  const [aggregations, setAggregations] = useState([]);

  //product
  const [products, setProducts] = useState([]);
  const [productSelected, setProductSelected] = useState("");

  //pagination
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingData, setLoadingData] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);

  //alert error
  const [alert, setAlert] = useState<Alert>({
    show: false,
    message: "",
    severity: "info",
  });

  //search field
  const [searchField, setSearchField] = useState<{
    uuid: string;
    product_id: string;
    label: string;
  }>({
    uuid: "",
    product_id: "",
    label: "",
  });

  //const
  let uuid = searchField.uuid === "" ? null : getUUID(searchField.uuid);
  let product_id =
    searchField.product_id === "" ? null : searchField.product_id;
  let label = searchField.label === "" ? null : searchField.label;

  //mounted
  useEffect(() => {
    setLoadingData(true);
    let data = {
      pagination: {
        page: 1,
        limit: 10,
        sort: null,
      },
      uuid: null,
      id: null,
      user_id: null,
      product_id: null,
      status: null,
      aggregation_parent_uuid: null,
      label: null,
    };
    //fetch aggregation list
    fetchAggregatePaginate(data)
      .then((result) => {
        setAggregations(result.aggregation);
        setTotalCount(result.pagination.total_rows);

        //fetch product
        fetchProduct()
          .then((result) => {
            setProducts(result);
            setLoadingData(false);
          })
          .catch((error) => {
            setLoadingData(false);
            setAlert({
              show: true,
              message: error.response.data.Detail,
              severity: "error",
            });
          });
      })
      .catch((error) => {
        setLoadingData(false);
        setAlert({
          show: true,
          message: error.response.data.Detail,
          severity: "error",
        });
      });
  }, []);

  //handle close snackbar error
  const handleOnClose = () => {
    setAlert({ show: false, message: "", severity: "info" });
  };

  //change page
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setLoadingData(true);

    setPage(newPage);
    let data = {
      pagination: {
        page: newPage + 1,
        limit: rowsPerPage,
        sort: null,
      },
      uuid: uuid,
      id: null,
      user_id: null,
      product_id: product_id,
      status: null,
      aggregation_parent_uuid: null,
      label: label,
    };
    fetchAggregatePaginate(data)
      .then((result) => {
        setLoadingData(false);
        setAggregations(result.aggregation);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  //rows per page
  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    //change page
    let perPage = parseInt(event.target.value);

    setLoadingData(true);
    setPage(0);
    setRowsPerPage(perPage);
    let data = {
      pagination: {
        page: page,
        limit: perPage,
        sort: null,
      },
      uuid: uuid,
      id: null,
      user_id: null,
      product_id: product_id,
      status: null,
      aggregation_parent_uuid: null,
      label: label,
    };
    fetchAggregatePaginate(data)
      .then((result) => {
        setLoadingData(false);
        setAggregations(result.aggregation);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  const handleChangeProductSelected = (event: SelectChangeEvent) => {
    setSearchField({ ...searchField, product_id: event.target.value });
    setProductSelected(event.target.value);
  };

  //handle search
  const handleSearch = () => {
    setSearchField({
      uuid: "",
      label: searchField.label,
      product_id: searchField.product_id,
    });
    setLoadingData(true);
    let data = {
      pagination: {
        page: 1,
        limit: 10,
        sort: null,
      },
      uuid: uuid,
      id: null,
      user_id: null,
      product_id: product_id,
      status: null,
      aggregation_parent_uuid: null,
      label: label,
    };
    //fetch aggregation list
    fetchAggregatePaginate(data)
      .then((result) => {
        setAggregations(result.aggregation);
        setTotalCount(result.pagination.total_rows);
        setLoadingData(false);
      })
      .catch((err) => {
        setLoadingData(false);
      });
  };

  return {
    handleOnClose,
    alert,
    productSelected,
    products,
    handleChangeProductSelected,
    handleSearch,
    loadingData,
    aggregations,
    totalCount,
    page,
    handleChangePage,
    rowsPerPage,
    handleRowsPerPageChange,
    setSearchField,
    searchField,
  };
};

export default useAggregationList;
